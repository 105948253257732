import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import star from '../assets/star.svg'; // Puedes reemplazar esto con cualquier icono
import discount from '../assets/discount.svg';
import TagManager from 'react-gtm-module';

const Promotion = ({ id, promotionname, description, image, end_date, discount_percent, restaurantname, city, restaurantid, address }) => {
    const s3url = process.env.REACT_APP_S3_URL;
    const navigate = useNavigate();
    const [redeemed, setRedeemed] = useState(false); // Estado para mostrar el código redimido
  
    // Función para formatear la fecha sin mostrar la hora
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toISOString().split('T')[0]; // Solo la parte de la fecha
    };

    // Función para manejar el clic en "Redimir"
    const handleRedeem = () => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'redeem_promotion',  // Nombre del evento
          restaurant: restaurantid,    // ID del restaurante
        },
      });
      setRedeemed(true); // Cambia el estado para mostrar el código
    };
  
    return (
      <div className="flex w-full max-w-md rounded overflow-hidden shadow-lg bg-white m-4">
        <div className="flex-none w-2/5">
          <img
            src={image ? `${image}` : discount}
            className="w-full object-cover rounded-lg shadow-lg"
            style={{ height: '150px' }}
          />
        </div>
        <div className="w-3/5 p-4 flex flex-col justify-between">
          <div>
            <div className="font-bold text-lg mb-2">{promotionname}</div>
            {/* El nombre del restaurante ahora es un enlace */}
            <div className="text-gray-700 text-sm mb-2">
              <a href={`/Restaurant?id=${restaurantid}`} className="text-blue-500 hover:underline font-bold text-lg">
                {restaurantname}
              </a> - {city}
            </div>
  
            {/* Mostrar el descuento solo si existe */}
            {discount_percent ? (
              <div className="flex items-center mb-2">
                <p className="ml-4 font-h1 font-bold text-h1">{discount_percent}%</p>
              </div>
            ) : null}
  
            <p className="text-gray-700 text-sm">{description}</p>
          </div>
          {/* Añadir la dirección del restaurante */}
          <p className="mt-3 text-gray-700 text-sm mb-2">Dirección: {address}</p>
          

          {/* Botón de Redimir */}
          {!redeemed ? (
            <button 
              onClick={handleRedeem} 
              className="bg-blue-500 text-white font-bold py-2 px-4 mb-2 rounded"
            >
              Redimir
            </button>
          ) : (
            <p className="text-green-500 font-bold">Código: 1000{id}</p> // Mostrar código después de redimir
          )}
          <p className="text-gray-700 text-sm mb-2">Válido hasta {formatDate(end_date)}</p>
        </div>
      </div>
    );
  };

const Promotions = () => {
  const [promotions, setPromotions] = useState([]);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchPromotions = async () => {
      try {
        const response = await fetch(`${apiUrl}/promotions`);
        const data = await response.json();
        setPromotions(data);
      } catch (err) {
        console.error('Error fetching promotions:', err);
      }
    };

    fetchPromotions();
  }, []);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mr-2 ml-2">
      {promotions.map(promotion => (
        <Promotion
          key={promotion.promotionid}
          id={promotion.promotionid}
          promotionname={promotion.promotionname}
          description={promotion.description}
          image={promotion.image}
          end_date={promotion.end_date}
          discount_percent={promotion.discount_percent}
          restaurantname={promotion.restaurantname}
          restaurantid={promotion.restaurantid}
          city={promotion.city}
          address={promotion.address} 
        />
      ))}
    </div>
  );
};

export default Promotions;
