import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import React, { useState, useEffect } from 'react';


const containerStyle = {
  width: '400px',
  height: '400px'
};

const center = {
  lat: -34.397,
  lng: 150.644
};

const positions = [
  { lat: -34.397, lng: 150.644, label: "A" },
  { lat: -34.390, lng: 150.650, label: "B" }
];

function Map() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey:'AIzaSyDbFYiUSkidDiDuVS0uEDF7N9PAWVEdd5M' // Define your API key in .env file
  });

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
    >
      {positions.map((pos, index) => (
        <Marker key={index} position={pos} label={pos.label} />
      ))}
    </GoogleMap>
  );
}

export default Map;