import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import eye from '../assets/eye.svg';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(1); // Step 1 for email/phone verification, Step 2 for password reset
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const handleVerify = async () => {
    try {
      const response = await fetch(`${apiUrl}/verify`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, phone })
      });
  
      const data = await response.json();
  
      if (response.ok) {
        // If the user is successfully verified, move to the password reset step
        setStep(2);
      } else {
        // If verification fails, alert the user
        alert(data.message || 'Datos incorrectos, intenta de nuevo.');
      }
    } catch (error) {
      console.error('Error verifying user:', error);
      alert('Datos incorrectos, intenta de nuevo.');
    }
  };

  const handleResetPassword = async () => {
    try {
      const response = await fetch(`${apiUrl}/resetpass`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, phone, newPassword })
      });
  
      const data = await response.json();
  
      if (response.ok) {
        // If the password is successfully reset, inform the user and possibly redirect to the login page
        alert('Contraseña actualizada exitosamente. Por favor ingresa con tu nueva contraseña');
        navigate('/Login');
      } else {
        // If the password reset fails, alert the user
        alert(data.message || 'Error al cambiar contraseña. Intenta de nuevo.');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      alert('Error al cambiar contraseña. Intenta de nuevo.');
    }
  };
  

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-2 rounded-lg max-w-md w-full mx-4">
        <img
          src={`${apiUrl}/images/assets/chevron-left.svg`}
          className="cursor-pointer w-8 h-8 mr-8 ml-0 pl-0"
          alt="Back"
          onClick={() => navigate('/Home')}
        />
        <h2 className="text-2xl font-bold mb-6 text-center">Recuperar Contraseña</h2>
        <div className="p-4">
          <form onSubmit={(e) => e.preventDefault()}>
            <input
              className="w-full p-2 border-2 border-black mb-4"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className="w-full p-2 border-2 border-black mb-4"
              type="tel"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            {step === 2 && (
              <div className="relative">
                <input
                  className="w-full p-2 border-2 border-black mb-4"
                  type={showPassword ? "text" : "password"}
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <img
                  src={eye}
                  className="absolute right-3 top-3 cursor-pointer"
                  alt="Toggle visibility"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
            )}
            {step === 1 && (
              <button
                className="w-full bg-yellow-400 p-3 rounded font-bold text-black mb-4"
                type="button"
                onClick={handleVerify}
              >
                Verificar
              </button>
            )}
            {step === 2 && (
              <button
                className="w-full bg-yellow-400 p-3 rounded font-bold text-black"
                type="button"
                onClick={handleResetPassword}
              >
                Actualizar contraseña
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
