import React , { useState }  from 'react';
import logo from '../assets/logo-color.png';
import logocolor from '../assets/Group.svg';
import searchIcon from '../assets/search.svg';
import menu from '../assets/menu.svg';
import styles from "../style.js";
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const NavbarRate = ({id,resName,logo}) => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const s3url = process.env.REACT_APP_S3_URL;

  

  const navigate = useNavigate();

  const handleHome =()=>{
    navigate('/Home');
    window.location.reload();
  }
    return (
      <div className="flex justify-between items-center px-4 py-1 bg-variable-collection-primary">
        <img
        src={`${s3url}/Images/restaurants/${id}/${logo}`}
        className="w-16 h-16 object-cover rounded-lg shadow-lg"
        alt="resLogo" />
          {/* Hide on small screens, show on large screens and above */}
          <div className="lg:block font-logo-font font-var(--logo-font-font-weight) text-white text-var(--logo-font-font-size) tracking-var(--logo-font-letter-spacing) leading-var(--logo-font-line-height) whitespace-nowrap">
            <a>{resName}</a>
          </div>
          <img className="cursor-pointer w-16 h-16" src={logocolor} alt="Logo"  onClick={handleHome}/>
      </div>
    );
  };
  
  export default NavbarRate
