import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";
import Navbar from './Navbar';
import NavbarRate from './NavbarRate';
import styles from "../style.js";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";



const CircularProgress = ({ score, total = 5 }) => {
  // Sizes for the SVG on different screens
  const size = {
    default: "120", // Default size
    sm: "80" // Smaller size for small screens
  };
  const strokeWidth = "10"; // Stroke width for the circles
  const radius = {
    default: 50, // Default radius
    sm: 33.33 // Smaller radius for small screens
  };

  const circumference = {
    default: 2 * Math.PI * radius.default,
    sm: 2 * Math.PI * radius.sm
  };
  const progress = score / total;
  const dashoffset = {
    default: circumference.default * (1 - progress),
    sm: circumference.sm * (1 - progress)
  };

  return (
    <div className="relative inline-flex items-center justify-center">
      <svg className="transform -rotate-90 w-full h-full sm:w-20 sm:h-20" viewBox="0 0 120 120" width={size.default} height={size.default}>
        {/* Background circle */}
        <circle
          cx="60"
          cy="60"
          r={radius.default}
          stroke="#e6e6e6"
          strokeWidth={strokeWidth}
          fill="transparent"
        />
        {/* Foreground circle */}
        <circle
          cx="60"
          cy="60"
          r={radius.default}
          stroke="#4ade80" // Tailwind CSS green-500
          strokeWidth={strokeWidth}
          fill="transparent"
          strokeDasharray={circumference.default}
          strokeDashoffset={dashoffset.default}
        />
      </svg>
      <div className="absolute flex flex-col items-center justify-center">
        <span className="text-3xl sm:text-2xl font-bold">{score}</span>
        <span className="text-lg sm:text-sm">/{total}</span>
      </div>
    </div>
  );
};


const Report = () => {
    const [logo, setLogo] = useState('');
    const [resValue, setResValue] = useState('');
    const [filter, setFilter] = useState('');
    const [resavg, setResavg] = useState('');
    const [foodavg, setFoodavg] = useState(0);
    const [serviceavg, setServiceavg] = useState(0);
    const [envavg, setEnvavg] = useState(0);
    const [countSurvey, setCountSurvey] = useState(0);
    const initialStartDate = new Date();
    initialStartDate.setDate(initialStartDate.getDate() - 15);
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(new Date());

    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const [foodRatingsChartData, setFoodRatingsChartData] = useState({
        options: {
          chart: {
            id: 'food-ratings-bar'
          },
          xaxis: {
            categories: []
          }
        },
        series: [{
          name: 'Food Ratings',
          data: []
        }]
      });
      
      const [foodSurveysChartData, setFoodSurveysChartData] = useState({
        options: {
          chart: {
            id: 'food-surveys-bar'
          },
          xaxis: {
            categories: []
          }
        },
        series: [{
          name: 'Food Surveys',
          data: []
        }]
      });

      const [surveyData, setSurveyData] = useState({
        options: {
          chart: {
            id: "basic-bar"
          },
          plotOptions: {
            bar: {
              columnWidth: '20%', // Adjust the bar width as needed
              dataLabels: {
                position: 'top', // Data labels will be positioned on top of the bars
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "";
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          xaxis: {
            type: 'datetime',
            categories: []  // This will be filled with date values
          },
          yaxis: {
            title: {
              text: 'Values'
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " points"
              }
            }
          }
        },
        series: [
          {
            name: "Comida Rating",
            data: []  // Initialize with empty data
          },
          {
            name: "Servicio Rating",
            data: []  // Initialize with empty data
          },
          {
            name: "Entorno Rating",
            data: []  // Initialize with empty data
          },
          {
            name: "Cantidad Encuestas",
            data: []  // Initialize with empty data
          }
        ]
      });

      const popperModifiers = [
    {
      name: 'offset',
      options: {
        offset: [0, 10],
      },
    },
    {
      name: 'preventOverflow',
      options: {
        rootBoundary: 'viewport',
      },
    },
  ];
      

  // Determine the screen width using window.innerWidth
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
    // Extract the data for the charts
    const prepareFoodCharts = (jsonDatafood) => {
        const foodNames = jsonDatafood.map(food => food.foodname);
        const foodRatings = jsonDatafood.map(food => Number(food.foodratings));
        const foodSurveys = jsonDatafood.map(food => Number(food.foodsurveys));
    
        setFoodRatingsChartData({
          options: {
            ...foodRatingsChartData.options,
            xaxis: {
              ...foodRatingsChartData.options.xaxis,
              categories: foodNames,
            },
            colors: ['#046380']
          },
          series: [{ ...foodRatingsChartData.series[0], data: foodRatings }]
        });
        
    
        setFoodSurveysChartData({
        options: {
            ...foodSurveysChartData.options,
            xaxis: { ...foodSurveysChartData.options.xaxis, categories: foodNames },
            colors: ['#212121']
        },
        
        series: [{ ...foodSurveysChartData.series[0], data: foodSurveys }]
        });
    };

    const formatDate = (value) => {
        let date;

        try {
            date = new Date(value);
        } catch (err) {
            return value; // return original if invalid
        }

        return date.toISOString().slice(0, 10);
    }
    const id = new URLSearchParams(window.location.search).get('id');

    const DataReport = async () => {
        try {
          const formattedStartDate = startDate.toISOString().substring(0, 10); // Assuming startDate is a Date object
          const formattedEndDate = endDate.toISOString().substring(0, 10); // Assuming endDate is a Date object
          const link = `${apiUrl}/reporte/${id}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
          const response = await fetch(link);
          const jsonData = await response.json();
      
          const categories = jsonData.map(entry => entry.created_at);
          const avgComida = jsonData.map(entry => entry.avg_comida);
          const avgServicio = jsonData.map(entry => entry.avg_servicio);
          const avgEntorno = jsonData.map(entry => entry.avg_entorno);
          const cantEncuestas = jsonData.map(entry => entry.cant_encuestas);
            setSurveyData({
                options: {
                    chart: {
                        id: "basic-bar"
                    },
                    colors: ['#212121', '#046380', '#00E396', '#FEB019'],
                    xaxis: {
                        type: 'datetime',
                        categories: categories,
                        labels: {
                            formatter: formatDate
                        }
                    }
                },
                series: [
                  {
                    name: "Cantidad Encuestas",
                    data: cantEncuestas
                },
                    {
                        name: "Comida Rating",
                        data: avgComida
                    },
                    {
                        name: "Servicio Rating",
                        data: avgServicio
                    },
                    {
                        name: "Ambiente Rating",
                        data: avgEntorno
                    },
                    
                ]
            });

        } catch (err) {
            console.log(err.message);
        }
        
        try {
            const link =  `${apiUrl}/res/` + id
            const response = await fetch(link);
            const jsonData = await response.json();
            setResValue(jsonData.restaurantname);
            setLogo(jsonData.logo);
  
        } catch (err) {
            console.log(err.message);
  
        }
    };


    const getResAvg = async () => {
      const formattedStartDate = startDate.toISOString().substring(0, 10);
      const formattedEndDate = endDate.toISOString().substring(0, 10);
      const popperModifiers = [
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            rootBoundary: 'viewport',
          },
        },
      ];
  
      // Create query parameters
      const queryParams = new URLSearchParams({
          ...(startDate && { startDate: formattedStartDate }),
          ...(endDate && { endDate: formattedEndDate })
      });
  
      try {
          const link = `${apiUrl}/resavg/${id}?${queryParams}`;
          const resp = await fetch(link);
          const jsonDat = await resp.json();
          setResavg(jsonDat.overallaverage);
          setFoodavg(jsonDat.averageratingfood);
          setServiceavg(jsonDat.averageratingservice);
          setEnvavg(jsonDat.averageratingenvironment);
          setCountSurvey(jsonDat.totalsurveys);
    
      } catch (err) {
          console.log(err.message);
      }
  };
  

    const getFood = async () => {
      const formattedStartDate = startDate.toISOString().substring(0, 10);
      const formattedEndDate = endDate.toISOString().substring(0, 10);
  
      try {
          const linkfood = `${apiUrl}/resfoodreport/${id}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
          const respofood = await fetch(linkfood);
          const jsonDatafood = await respofood.json();
          prepareFoodCharts(jsonDatafood);
      } catch (err) {
          console.log(err.message);
      }
  };

    useEffect(() => {
        DataReport();
        getResAvg();
        getFood();
        
    }, [startDate, endDate]);
    const { series } = surveyData
    return (
        <div className={`bg-navbar h-20 ${styles.paddingX} ${styles.paddingY}`}>
          <div className={`${styles.boxWidth}`}>
            <NavbarRate id={id} resName={resValue} logo={logo} />
            <div className="flex flex-col md:flex-row">
      
              {/* Left side with circular progress */}
              <div className="flex flex-col md:w-1/2 p-8">
              <div className="flex items-center justify-center bg-primarybg rounded-lg shadow-md">
            <div className="flex items-center space-x-1 md:space-x-3">
                <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Start Date"
                    className="w-24 md:w-32 md:px-4 py-2 border-2 rounded-md text-primary focus:ring-ctacolor focus:border-ctacolor placeholder:italic"
                />
                <span className="text-secundary">to</span>
                <DatePicker
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="End Date"
                    className=" w-24 md:w-32 mr-4 md:px-4 py-2 border-2 rounded-md text-primary focus:ring-ctacolor focus:border-ctacolor placeholder:italic"
                />
            </div>
        </div>

                <div className="flex flex-row md:flex-row mt-5">
                    <div className="m-4">
                        <p className="font-h1 font-bold text-h2">Cantidad de Encuestas</p>
                        <p className="font-h1 font-bold text-title text-center mt-5">{countSurvey}</p>
                    </div>
                    <div className="m-4">
                        <p className="font-h1 font-bold text-h2"> Restaurante</p>
                        <CircularProgress score={resavg} />
                    </div>
                </div>
                <div className="mt-12 md:mt-20 flex flex-row md:flex-row">
                    <div className="m-2 md:mx-8">
                    <p className="font-h1 font-bold text-h2 text-center"> Comida</p>
                    <CircularProgress score={foodavg} />
                    </div>
                    <div className="m-2 md:mx-8">
                    <p className="font-h1 font-bold text-h2 text-center"> Servicio</p>
                    <CircularProgress score={serviceavg} />
                    </div>
                    <div className="m-2 md:mx-8">
                    <p className="font-h1 font-bold text-h2 text-center"> Ambiente</p>
                    <CircularProgress score={envavg} />
                    </div>
                </div>
              </div>
      
              {/* Right side with charts */}
              <div className="flex flex-col md:w-1/2 p-2">
              <div className="flex flex-col p-2 items-center">
                <p className="md:mt-20 font-h1 font-bold text-h2 text-center">Cantidad de Encuestas</p>
                <div className="w-full flex justify-center">
                  <Chart
                    options={surveyData.options}
                    series={surveyData.series.filter(s => s.name === 'Cantidad Encuestas')}
                    type="bar"
                    width={isSmallScreen ? "120%" : "180%"}
                    height="150%"
                  />
                </div>
              </div>
              <div className="flex flex-col p-2 items-center">
                <p className="font-h1 font-bold text-h2 text-center mt-8"> Resultado Encuestas</p>
                  <Chart
                    options={surveyData.options}
                    series={filter === 'comida'
                      ? series.filter(s => s.name === 'Comida Rating')
                      : filter === 'servicio'
                      ? series.filter(s => s.name === 'Servicio Rating')
                      : filter === 'ambiente'
                      ? series.filter(s => s.name === 'Ambiente Rating')
                      : surveyData.series}
                    type="bar"
                    width={isSmallScreen ? "120%" : "180%"}
                    height="150%"
                    
                  />
                </div>
              </div>
              
            </div>
            <div className="flex flex-col mt-5">
            

            {/* New Food Surveys Bar Chart */}

            <div className="w-full">
              <div className=" flex flex-col p-1 items-center">
                <p className="font-h1 font-bold text-h2 text-center"> No. Calificaciones por Plato</p>
                <Chart
                    options={foodSurveysChartData.options}
                    series={foodSurveysChartData.series}
                    type="bar"
                    width={isSmallScreen ? "120%" : "350%"}
                    height="150%"
                />

              </div>

              <div className="flex flex-col p-2 mt-3 items-center">
                <p className="font-h1 font-bold text-h2 text-center">Resultado por plato</p>

                <Chart
                    options={foodRatingsChartData.options}
                    series={foodRatingsChartData.series}
                    type="bar"
                    width={isSmallScreen ? "120%" : "350%"}
                    height="150%"
                />
              </div>

            </div>
      </div>
      
          </div>
        </div>
      );
}

export default Report;

