import styles from "../style.js";
import Navbar from "./Navbar.js";
import Footer from './Footer';
import addres from '../assets/addres.jpg';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

const Addtransform = ({  city, setCity }) => {
    const scrollToInformation = (event) => {
        event.preventDefault();
        document.getElementById('paso2').scrollIntoView({ behavior: 'smooth', block: 'start'});
    };
    return (
        <div>

        <h1 className="mx-4 text-h1 md:text-title text-center font-logo-font font-title">Estás a solo unos pasos de transformar tu restaurante</h1>
        <div className="flex flex-col md:flex-row md:items-start text-primary">
            <div className="w-full md:w-1/2 p-8 flex flex-col justify-between">
                <div className="flex flex-col items-center justify-between flex-grow">
                    <p className="text-h1 font-h1 font-bold mb-12 text-center"> Paso 1: Ciudad del restaurante</p>
                    <div className="w-full relative mb-12 px-16 md:px-20">
                        <select value={city} onChange={(e) => setCity(e.target.value)} className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-3 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                            <option className="text-blue">Seleccionar</option>
                            <option>Bogotá</option>
                            <option>Medellín</option>
                            <option>Cali</option>
                            <option>Barranquilla</option>
                            <option>Cúcuta</option>
                            <option>Bucaramanga</option>
                            <option>Cartagena</option>
                            <option>Pereira </option>
                            <option>Santa Marta</option>
                            <option>Valledupar</option>
                            <option>Neiva</option>
                            <option>Armenia</option>
                            <option>Popayán</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-16 md:pr-20 text-gray-700">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M5.5 7l4.5 4.5L14.5 7H5.5z" />
                            </svg>
                        </div>
                    </div>
                    <button onClick={scrollToInformation}
                     className="bg-ctacolor rounded-[20px] border-2 border-solid border-black py-2 px-8 font-h1 font-bold text-h2">
                        Siguiente
                    </button>
                </div>
            </div>
            <div className="w-full md:w-1/2 flex justify-center items-center p-9 pb-5">
                <iframe
                    style={{ width: '660px', height: '415px', maxWidth: '100%' }}
                    src="https://www.youtube.com/embed/OzHl8auXHkE"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    className="md:max-w-sm md:max-h-full w-auto h-auto object-contain mx-auto">
                </iframe>
            </div>
        </div>
        </div>
    );
}

const Information = ({ errors, restaurantname, setRestaurantname, nit, setNit, address, setAddress, phone, setPhone, menu, setMenu, scheduler, setScheduler, webpage, setwebpage, short_desc, setShort_desc, logo, setLogo, resImages, setResImages, petFriendly, setPetFriendly }) => { 
    return (
        <div className="p-5">
            <p id='paso2' className="text-h1 font-h1 font-bold mb-12 text-center">Paso 2: Cuéntanos de tu restaurante</p>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-3">
                    <label className="block text-primary font-body-bold mb-2" htmlFor="nombre">Nombre del Restaurante*</label>
                    <input value={restaurantname} onChange={(e) => setRestaurantname(e.target.value)} className={`shadow appearance-none border ${errors.restaurantname ? 'border-red-500' : 'rounded'} w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`} id="nombre" type="text" placeholder="Nombre del restaurante" />
                    {errors.restaurantname && <p className="text-red-500 text-xs italic">{errors.restaurantname}</p>}
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-3">
                    <label className="block text-primary font-body-bold mb-2" htmlFor="direccion">Dirección*</label>
                    <input value={address} onChange={(e) => setAddress(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="direccion" type="text" placeholder="Cra 44 # 23-12" />
                    {errors.address && <p className="text-red-500 text-xs italic">{errors.address}</p>}
                </div>
                <div className="w-full md:w-1/2 px-3 mb-3 md:mb-0">
                    <label className="block text-primary font-body-bold mb-2" htmlFor="telefono">Telefono</label>
                    <input value={phone} onChange={(e) => setPhone(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="telefono" type="text" placeholder="31444493198" />
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-3">
                    <label className="block text-primary font-body-bold mb-2" htmlFor="direccion">Pagina web / link del menú</label>
                    <input value={webpage} onChange={(e) => setwebpage(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="direccion" type="text" placeholder="www.restaurante.com" />
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-3">
                    <label className="block text-primary font-body-bold mb-2" htmlFor="direccion">Horario*</label>
                    <textarea value={scheduler} onChange={(e) => setScheduler(e.target.value)} className="shadow appearance-none border rounded w-full py-7 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="direccion" placeholder="Discriminar por días"></textarea>
                </div>
                <div className="w-full md:w-1/2 px-3">
                    <label className="block text-primary font-body-bold mb-2" htmlFor="telefono">Descripción*</label>
                    <textarea value={short_desc} onChange={(e) => setShort_desc(e.target.value)} className="shadow appearance-none border rounded w-full py-7 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="telefono" placeholder="Breve descripción"></textarea>
                    {errors.short_desc && <p className="text-red-500 text-xs italic">{errors.short_desc}</p>}
                </div>
               
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-3">
                    <label className="block text-primary font-body-bold mb-2" htmlFor="direccion">Logo*</label>
                    <input type="file" accept="image/*" onChange={(e) => setLogo(e.target.value)} className="shadow appearance-none border rounded w-full py-7 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="logo" />
                    {errors.logo && <p className="text-red-500 text-xs italic">{errors.logo}</p>}
                </div>
                <div className="w-full md:w-1/2 px-3">
                    <label className="block text-primary font-body-bold mb-2" htmlFor="telefono">Imagenes del restaurante (Múltiples Imagenes)</label>
                    <input 
                        type="file" 
                        accept="image/*" 
                        multiple
                        onChange={(e) => setResImages(e.target.files)} // Change to set multiple files
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        id="resImages" 
                        />

            <div className="w-full md:w-1/2 px-3 mt-4 mb-6 md:mb-3 flex items-center">
                            <label className="text-primary font-body-bold mb-2" htmlFor="petFriendly">Pet Friendly </label>
                                <input
                                    type="checkbox"
                                    checked={petFriendly}
                                    onChange={(e) => setPetFriendly(e.target.checked)}
                                    className="ml-4 mr-2 leading-tight mb-2"
                                    id="petFriendly"
                                />
                                
                            </div>
                </div>
            </div>
        </div>
    )
}


const Addrestaurant = ({ userData }) => {
    const [restaurantname, setRestaurantname] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [scheduler, setScheduler] = useState('');
    const [city, setCity] = useState('');
    const [webpage, setwebpage] = useState('');
    const [short_desc, setShort_desc] = useState('');
    const [nit, setNit] = useState('');
    const [menu, setMenu] = useState('');
    const [logo, setLogo] = useState();
    const [resImages, setResImages] = useState([]);
    const [petFriendly, setPetFriendly] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const { username, restaurantid, userid } = userData;

    const validateForm = () => {
        const newErrors = {};
      
        if (!restaurantname.trim()) newErrors.restaurantname = 'Nombre del restaurante es requerido';
        if (!address.trim()) newErrors.address = 'Dirección es requerida';
        if (!city.trim() || city === 'Seleccionar') newErrors.city = 'Ciudad es requerida';
        if (!short_desc.trim()) newErrors.short_desc = 'Descripción es requerida';
        if (!logo) newErrors.logo = 'Logo es requerido';
      
        setErrors(newErrors);
      
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        let restaurantId;
        const logoFile = e.target.logo.files[0];
        const logoname = logoFile ? logoFile.name : '';
        try {
            const body = { city, restaurantname, nit, address, phone, webpage, menu, scheduler, short_desc, userid, logoname, pet_friendly: petFriendly };
            const response = await fetch(`${apiUrl}/resadd`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(body)
            });

            const responseData = await response.json();
            if (response.ok) {
                restaurantId = responseData.restaurantId;
                console.log("Restaurant ID:", restaurantId);

                // Upload the logo image
                const logo = e.target.logo.files[0];
                const formData = new FormData();
                formData.append('image', logo);
                formData.append('restaurantId', restaurantId.toString());

                try {
                    const imgResponse = await fetch(`${apiUrl}/upload`, {
                        method: 'POST',
                        body: formData,
                    });

                    if (imgResponse.ok) {
                        const data = await imgResponse.json();
                        console.log('Uploaded logo URL:', data.imageUrl);
                    } else {
                        console.error('Error uploading the logo');
                    }
                } catch (error) {
                    console.error('Error uploading the logo:', error);
                }

                // Upload multiple restaurant images
                const resImages = e.target.resImages.files;
                const imagesFormData = new FormData();
                for (let i = 0; i < resImages.length; i++) {
                    imagesFormData.append('resImages', resImages[i]);
                }
                imagesFormData.append('restaurantId', restaurantId.toString());

                try {
                    const imagesResponse = await fetch(`${apiUrl}/uploadMultiple`, {
                        method: 'POST',
                        body: imagesFormData,
                    });

                    if (imagesResponse.ok) {
                        const imagesData = await imagesResponse.json();
                        console.log('Uploaded images URLs:', imagesData);
                    } else {
                        console.error('Error uploading images');
                    }
                } catch (error) {
                    console.error('Error uploading images:', error);
                }
            } else {
                console.error('Error saving restaurant data', responseData);
            }

            // Reset form fields and navigate
            setRestaurantname("");
            setAddress("");
            setPhone("");
            setScheduler("");
            setCity("");
            setwebpage("");
            setShort_desc("");
            setNit("");
            setMenu("");
            setLogo();
            setResImages([]);
            setPetFriendly(false);
            navigate(`/Menu?id=${restaurantId}`);

        } catch (err) {
            console.log(err.message);
            alert('Error saving restaurant data');
        }
    };

    return (
        <div className="bg-primarybg w-full overflow-hidden">
            <div className={`bg-navbar h-20 ${styles.paddingX} ${styles.paddingY}`}>
                <div className={`${styles.boxWidth}`}>
                    <Navbar name={username} userResid={restaurantid} />
                </div>
            </div>

            <form onSubmit={handleSubmit} className='undernav'>
                <div className={`bg-primarybg ${styles.flexStart}`}>
                    <div className={`${styles.boxWidth}`}>
                        <div className="font-logo-font">
                            <Addtransform city={city} setCity={setCity} />
                        </div>
                    </div>
                </div>

                <div className={`bg-primarybg ${styles.paddingX} ${styles.flexStart}`}>
                    <div className={`${styles.boxWidth}`}>
                        <Information
                            errors={errors}
                            restaurantname={restaurantname}
                            setRestaurantname={setRestaurantname}
                            address={address}
                            setAddress={setAddress}
                            phone={phone}
                            setPhone={setPhone}
                            menu={menu}
                            setMenu={setMenu}
                            scheduler={scheduler}
                            setScheduler={setScheduler}
                            webpage={webpage}
                            setwebpage={setwebpage}
                            short_desc={short_desc}
                            setShort_desc={setShort_desc}
                            nit={nit}
                            setNit={setNit}
                            logo={logo}
                            setLogo={setLogo}
                            setResImages={setResImages}
                            petFriendly={petFriendly}
                            setPetFriendly={setPetFriendly}
                        />
                    </div>
                </div>
                <div className="flex justify-center">
                    <button type="submit" className="mx-1 mb-5 px-8 py-2 font-h1 font-bold text-h2 bg-ctacolor rounded-[20px] border-2 border-solid border-black">Continuar</button>
                </div>
            </form>
            <Footer />
        </div>
    );
}

export default Addrestaurant;
