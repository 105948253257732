// src/gtm.js
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-N6PGKT7K', // Reemplaza con tu ID de GTM
};

export const initializeGTM = () => {
  TagManager.initialize(tagManagerArgs);
};
