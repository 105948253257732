import logo from '../assets/logo-color.png';
import logocolor from '../assets/Group.svg';
import searchIcon from '../assets/search.svg';
import menu from '../assets/menu.svg';
import TagManager from 'react-gtm-module';
import styles from "../style.js";
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';

const SearchSuggestions = ({ suggestions, onSelectSuggestion, closeSuggestions }) => {
  const suggestionsRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        closeSuggestions();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [suggestionsRef]);

  return (
    <div ref={suggestionsRef} className="absolute bg-white w-full shadow-lg z-10">
      {suggestions.length > 0 && (
        <div>
          <div className="p-2 text-gray-700 font-semibold">Sugerencias</div>
          <ul>
            {suggestions.map((suggestion, index) => (
              <li 
                key={index}
                className="p-2 cursor-pointer hover:bg-gray-200"
                onClick={() => onSelectSuggestion(suggestion)}
              >
                {suggestion.restaurantname}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const Box = ({ name, onSearch }) => {
  const userName = name ? name : '';
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const handleSearchChange = async (event) => {

    TagManager.dataLayer({
      dataLayer: {
        event: 'search_start',
      },
    });
    const term = event.target.value;
    setSearchTerm(term);
    if (term) {
      const suggestionsData = await fetchSuggestions(term);
      setSuggestions(suggestionsData);
    } else {
      setSuggestions([]);
    }
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
// Enviar evento a Google Tag Manager
    TagManager.dataLayer({
      dataLayer: {
        event: 'search',
        searchTerm: searchTerm,
      },
    });

    onSearch(searchTerm);
    setSuggestions([]); // Clear suggestions
    if (window.location.pathname !== '/Home') {
      navigate('/Home');
      window.location.reload();
    }
  };

  const fetchSuggestions = async (term) => {
    try {
      const response = await fetch(`${apiUrl}/res?query=${term}`, {
        credentials: 'include',
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      return [];
    }
  };

  const handleSelectSuggestion = (suggestion) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'suggestion_selected',
      },
    });
    setSearchTerm(suggestion.restaurantname);
    setSuggestions([]);
    onSearch(suggestion.restaurantname);
    navigate(`/Restaurant?id=${suggestion.restaurantid}`);
  };

  const closeSuggestions = () => {
    setSuggestions([]);
  };

  return (
    <div className="relative w-full ml-2 sm:ml-72 mr-2 sm:mr-72">
      <form
        onSubmit={handleSearchSubmit}
        className="flex justify-between items-center px-1 w-full h-12 border-4 text-logo-font-mov md:text-logo-font-sm border-contrast rounded-lg"
      >
        <input
          className="font-logo-font w-full bg-transparent text-white placeholder-white outline-none text-sm md:text-m"
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder={`Busca comida, restaurantes y más`}
          inputMode="search"
          pattern=".*"
        />
        <button type="submit" aria-label="Search">
          <img className="w-8 h-8" src={searchIcon} alt="Search" />
        </button>
      </form>
      {suggestions.length > 0 && (
        <SearchSuggestions 
          suggestions={suggestions} 
          onSelectSuggestion={handleSelectSuggestion}
          closeSuggestions={closeSuggestions}
        />
      )}
    </div>
  );
};

const Dropdown = ({ isOpen, setIsOpen, userid, userResid, onShowFavorites }) => {
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const handleLogout = async () => {
    try {
      const response = await fetch(`${apiUrl}/logout`, {
        method: "GET",
        credentials: 'include' // Important to include for session-based auth
      });
  
      if (response.ok) {
        alert('Sesión cerrada con éxito');
      } else {
        alert('Error al cerrar sesión');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error al cerrar sesión');
    }
    navigate(`/Login`);
  };

  if (!isOpen) return null;

  return (
    <div ref={dropdownRef} className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
      <div 
      className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white cursor-pointer" 
      onClick={() => {
        navigate(`/Home`);
        window.location.reload(); // Refresh the page after navigation
      }}  
    >
      Inicio
    </div>
      {userResid ? (
        <>
          <a className="cursor-pointer block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white" onClick={() => navigate(`/Report?id=${userResid}`)}>Reporte de restaurante</a>
          <a className="cursor-pointer block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white" onClick={() => navigate(`/Editrestaurant?id=${userResid}`)}>Gestionar Restaurante</a>
          <a className="cursor-pointer block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white" onClick={() => navigate(`/Editpromo?id=${userResid}`)}>Gestionar promos</a>
          <a className="cursor-pointer block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white" onClick={() => navigate(`/Editmenu?id=${userResid}`)}>Gestionar Menú</a>
        </>
      ) : (
        userid ? (
          <div className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white cursor-pointer" onClick={() => navigate(`/Add`)}>
            Pon tu Restaurante
          </div>
        ) : (
          <a onClick={() => navigate(`/Login`)} className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white">Pon tu Restaurante</a>
        )
      )}
      {userid ? (
        <div>
          <div className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white cursor-pointer" onClick={onShowFavorites}>
            Lista de Favoritos
          </div>
          <div className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white cursor-pointer" onClick={handleLogout}>
            Cerrar Sesión
          </div>
        </div>
      ) : (
        <>
          <a href="/Login" className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white">
            Lista de Favoritos
          </a>
          <a href="/Login" className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white">
            Iniciar Sesion
          </a>
          <a href="/Signup" className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white">
            Registrarse
          </a>
        </>
      )}
    </div>
  );
};

const Navbar = ({ name, userResid, userid, onSearch, onShowFavorites, onClearFilters }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/Add');
  };

  const handleHome = () => {
    try {
      navigate('/Home');
      onClearFilters();
      window.location.reload();
    } catch {
      navigate('/Home');
      window.location.reload();
    }
  };

  return (
    <div className="flex justify-between items-center px-4 py-1 bg-variable-collection-primary">
      <img className="w-14 h-16 mr-2 sm:mr-2 md:mr-0 cursor-pointer" onClick={handleHome} src={logocolor} alt="Logo" />
      <Box name={name} onSearch={onSearch} />
      <Menu userid={userid} userResid={userResid} handleNavigate={handleNavigate} handleHome={handleHome} onShowFavorites={onShowFavorites} />
    </div>
  );
};

const Menu = ({ userid, userResid, handleNavigate, handleHome, onShowFavorites }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <button onClick={() => setIsOpen(!isOpen)} className="cursor-pointer h-12 border-4 border-contrast rounded-lg flex justify-center gap-1 items-center px-1 ">
        <img className="w-12 sm:w-20 h-20" src={menu} alt="Menu" />
      </button>
      <Dropdown isOpen={isOpen} setIsOpen={setIsOpen} userid={userid} userResid={userResid} onShowFavorites={onShowFavorites} />
    </div>
  );
};

export default Navbar;
