import React, { useState } from 'react';
import { categoriesImages } from '../constants';
import styles from "../style.js";
import arrow from '../assets/ArrowIcon.svg';
import filter from '../assets/filter.svg';
import { Range, getTrackBackground } from 'react-range';
import TagManager from 'react-gtm-module';

const STEP = 1000;
const MIN = 0;
const MAX = 100000;


const distanceOptions = [
    { label: "Unas cuadras de distancia", value: 1 },
    { label: "Cerca de mi ubicación", value: 5 },
    { label: "distancia media", value: 10 },
    { label: "Toda la ciudad", value: 30 },
    { label: "Todo el país", value: null },
];

const Categories = ({ onSelectCategory, selectedCategory, onSetFilters, showPromotions, setShowPromotions }) => {
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [rating, setRating] = useState(0);
    const [city, setCity] = useState('');
    const [priceRange, setPriceRange] = useState([0, 100000]);
    const [surveyCount, setSurveyCount] = useState(0);
    const [petFriendly, setPetFriendly] = useState(false);
    const [distance, setDistance] = useState('');

    const scroll = (direction) => {
        const container = document.getElementById('categoriesContainer');
        const scrollCompleted = direction === 'left' ? -300 : 300;
        container.scrollBy({ left: scrollCompleted, behavior: 'smooth' });
    };

    const handleCategoryClick = (category) => {

        TagManager.dataLayer({
            dataLayer: {
              event: 'select_category',
              category: category,
            },
          });
        if (selectedCategory === category.toLowerCase()) {
            onSelectCategory('');
        } else {
            onSelectCategory(category.toLowerCase());
        }
    };

    const toggleFilterModal = () => {
        TagManager.dataLayer({
            dataLayer: {
              event: 'filters_open'
            },
          });
        setIsFilterModalOpen(!isFilterModalOpen);
    };

    const toggleShowPromotions = () => {
        TagManager.dataLayer({
            dataLayer: {
              event: 'show_promotions'
            },
          });
        setShowPromotions(!showPromotions);
      };

    const applyFilters = () => {
        TagManager.dataLayer({
            dataLayer: {
              event: 'filters_applied'
            },
          });
        onSetFilters({ city, rating, priceRange, surveyCount, petFriendly, distance  });
        setIsFilterModalOpen(false);
    };

    const resetFilters = () => {
        setRating(0);
        setCity('');
        setPriceRange([MIN, MAX]);
        setSurveyCount(0);
        setPetFriendly(false);
        setDistance('');
        onSetFilters({ city: '', rating: 0, priceRange: [MIN, MAX], surveyCount: 0, petFriendly: false });
    };
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 0
        }).format(value);
    };

    return (
        <div className="flex flex-col">
            <div className="flex items-center overflow-hidden px-5 md:ml-10">
                <button onClick={() => scroll('left')} className="focus:outline-none">
                    <img src={arrow} alt="Desplazar izquierda" className="transform rotate-180 w-12 md:w-8 h-12 md:h-8" />
                </button>

                <div id="categoriesContainer" className="flex flex-row gap-10 overflow-x-auto mx-5 mt-5" style={{ scrollbarWidth: 'none' }}>
                    {categoriesImages.map((item, index) => (
                        <div key={index} className="font-body flex flex-col items-center cursor-pointer" onClick={() => handleCategoryClick(item.title.toLowerCase())}>
                            <img src={item.icon} alt={item.title} className={`w-10 h-16 ${selectedCategory === item.title.toLowerCase() ? 'shadow-lg' : ''}`} />
                            <span>{item.title}</span>
                        </div>
                    ))}
                </div>

                <button onClick={() => scroll('right')} className="focus:outline-none mr-5">
                    <img src={arrow} alt="Desplazar derecha" className="w-12 md:w-8 h-12 md:h-8" />
                </button>
            </div>
            <div className="flex justify-center items-center space-x-4 pl-2">
            <div className="w-full sm:w-auto">
                <button onClick={toggleFilterModal} className="flex items-center space-x-2 py-4 pl-2 pr-7 border rounded-md focus:outline-none focus:border-blue-500" type="button">
                    <img src={filter} alt="Filtros" className="w-6 h-6" />
                    <span>Filtros</span>
                </button>
            </div>

            <div className="w-full sm:w-auto">
                <button onClick={toggleShowPromotions} className="bg-ctacolor w-44 rounded-[20px] border-2 border-solid border-black py-2 px-8 font-h1 font-bold text-h2" type="button">
                    <span>{showPromotions ? "Restaurantes" : "% Promos"}</span>
                </button>
            </div>
        </div>             
            {isFilterModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-4 rounded-lg shadow-lg max-w-sm m-auto mt-20 relative">
                        <button onClick={toggleFilterModal} className="absolute top-0 right-0 p-2">
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>

                        <div className='mt-5 pr-4 pl-4'>
                            <label htmlFor="distance" className="block text-sm font-medium text-gray-700">Distancia</label>
                            <select
                                id="distance"
                                value={distance}
                                onChange={(e) => setDistance(e.target.value)}
                                className="block mt-1 w-full border-indigo-500 pl-3 pr-10 py-2 text-base sm:text-sm rounded-md"
                            >
                                <option value="">Seleccionar distancia</option>
                                {distanceOptions.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className='mt-5 pr-4 pl-4'>
                            <label htmlFor="city" className="block text-sm font-medium text-gray-700">Ciudad</label>
                            <select
                                id="city"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                className="block mt-1 block w-full border-indigo-500 pl-3 pr-10 py-2 text-base sm:text-sm rounded-md"
                            >
                                <option value="">Seleccionar</option>
                                <option value="Bogotá">Bogotá</option>
                                <option value="Medellín">Medellín</option>
                                <option value="Cali">Cali</option>
                                <option value="Barranquilla">Barranquilla</option>
                                <option value="Cúcuta">Cúcuta</option>
                                <option value="Bucaramanga">Bucaramanga</option>
                                <option value="Cartagena">Cartagena</option>
                                <option value="Pereira">Pereira</option>
                                <option value="Santa Marta">Santa Marta</option>
                                <option value="Valledupar">Valledupar</option>
                                <option value="Neiva">Neiva</option>
                                <option value="Armenia">Armenia</option>
                                <option value="Popayán">Popayán</option>
                            </select>
                        </div>

                        <div className='mt-5 pr-4 pl-4'>
                            <label htmlFor="rating" className="block text-sm font-medium text-gray-700">
                                Calificación: {rating}
                            </label>
                            <input
                                type="range"
                                id="rating"
                                name="rating"
                                min="0"
                                max="5"
                                step="0.1"
                                value={rating}
                                onChange={(e) => setRating(e.target.value)}
                                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                            />
                        </div>

                        <div className='mt-5 pr-4 pl-4'>
                            <label htmlFor="priceRange" className="block text-sm font-small text-gray-700">
                                Precio Promedio:{formatCurrency(priceRange[0])} - {formatCurrency(priceRange[1])}
                            </label>
                            <Range
                                values={priceRange}
                                step={STEP}
                                min={MIN}
                                max={MAX}
                                onChange={values => setPriceRange(values)}
                                renderTrack={({ props, children }) => (
                                    <div
                                        {...props}
                                        style={{
                                            ...props.style,
                                            height: '6px',
                                            width: '100%',
                                            background: getTrackBackground({
                                                values: priceRange,
                                                colors: ['#ccc', '#548BF4', '#ccc'],
                                                min: MIN,
                                                max: MAX
                                            })
                                        }}
                                    >
                                        {children}
                                    </div>
                                )}
                                renderThumb={({ props, index }) => (
                                    <div
                                        {...props}
                                        style={{
                                            ...props.style,
                                            height: '16px',
                                            width: '16px',
                                            borderRadius: '50%',
                                            backgroundColor: '#548BF4',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            boxShadow: '0px 2px 6px #AAA'
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: '16px',
                                                width: '5px',
                                                backgroundColor: index === 0 ? '#548BF4' : '#548BF4'
                                            }}
                                        />
                                    </div>
                                    
                                )}
                            />
                        </div>

                        <div className='mt-5 pr-4 pl-4'>
                            <label htmlFor="surveyCount" className="block text-sm font-medium text-gray-700">
                                Cantidad de Encuestas mínimas: {surveyCount}
                            </label>
                            <input
                                type="range"
                                id="surveyCount"
                                name="surveyCount"
                                min="0"
                                max="100"
                                step="5"
                                value={surveyCount}
                                onChange={(e) => setSurveyCount(e.target.value)}
                                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointe
                                +
                                r dark:bg-gray-700"
                            />
                        </div>

                        <div className='mt-5 pr-4 pl-4 flex items-center'>
                            <input
                                type="checkbox"
                                checked={petFriendly}
                                onChange={(e) => setPetFriendly(e.target.checked)}
                                className="mr-2 leading-tight"
                                id="petFriendly"
                            />
                            <label htmlFor="petFriendly" className="text-sm font-medium text-gray-700">Pet Friendly</label>
                        </div>

                        <div className="flex justify-between mt-5">
                            <button onClick={resetFilters}
                                    className="bg-white border border-black rounded-[20px] py-2 px-8 font-h1 font-bold text-h3">
                                Reiniciar
                            </button>
                            <button onClick={applyFilters}
                                    className="bg-ctacolor w-44 rounded-[20px] border-2 border-solid border-black py-2 px-8 font-h1 font-bold text-h2">
                                Filtrar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Categories;
