import styles from "../style.js";
import Navbar from "./Navbar.js";
import Footer from './Footer';
import { categoriesImages } from '../constants'
import addres from '../assets/addres.jpg';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';


const Information = ({restaurantname, food, setfood, setShouldRefresh}) => { 
    const [foodName, setfoodName] = useState('');
    const [foodType, setfoodType] = useState('');
    const [foodCategory, setfoodCategory] = useState('');
    const [foodDesc, setfoodDesc] = useState('');
    const [foodPrice, setfoodPrice] = useState(0);
    const [foodImage, setFoodImage] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const id = new URLSearchParams(window.location.search).get('id');
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const [isUpdating, setIsUpdating] = useState(false); // New state to track if we are updating an item


    const groupByTipo = (foodArray) => {
        return foodArray.reduce((acc, item) => {
            // If the foodtype doesn't exist in the accumulator, add it as a key and set its value to an array containing the current item
            if (!acc[item.foodtype]) {
                acc[item.foodtype] = [];
            }
            // Push the current item to the array for its foodtype
            acc[item.foodtype].push(item);
            return acc;
        }, {});
    };
    const groupedFood = groupByTipo(food);


    const updateFood = async (e) => {
        e.preventDefault();
        const updatedFood = {
            nombrePlato: foodName,
            tipo: foodType,
            categoria: foodCategory,
            desc: foodDesc,
            price: foodPrice,
            image: foodImage,
            restauntId: id,
        };
    
        try {
            const formData = new FormData();
            formData.append('foodImage', updatedFood.image);
            formData.append('foodName', updatedFood.nombrePlato);
            formData.append('foodType', updatedFood.tipo);
            formData.append('foodCategory', updatedFood.categoria);
            formData.append('foodDesc', updatedFood.desc);
            formData.append('foodPrice', updatedFood.price);
            formData.append('restaurantId', updatedFood.restauntId);
    
            const response = await fetch(`${apiUrl}/updatefood/${selectedItem.foodid}`, {
                method: 'PUT',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Error while updating food item');
            }
    
            const responseData = await response.json();
            console.log(responseData);
    
            // Reset form and switch to "add" mode
            resetForm();
    
            // Trigger re-render by setting shouldRefresh to true
            setShouldRefresh(true);
            setIsUpdating(false);
        } catch (err) {
            console.error(err.message);
        }
    };



    const addFood = async (e) => {
        e.preventDefault();
        const newFood = { nombrePlato: foodName, tipo: foodType, categoria: foodCategory, desc: foodDesc, price: foodPrice, image: foodImage, restauntId: id };
    
        try {
            const formData = new FormData();
            formData.append('foodImage', newFood.image);
            formData.append('foodName', newFood.nombrePlato);
            formData.append('foodType', newFood.tipo);
            formData.append('foodCategory', newFood.categoria);
            formData.append('foodDesc', newFood.desc);
            formData.append('foodPrice', newFood.price);
            formData.append('restaurantId', newFood.restauntId);
    
            const response = await fetch(`${apiUrl}/foodadd`, {
                method: 'POST',
                body: formData
            });
    
            if (!response.ok) {
                throw new Error('Error while adding food item');
            }
    
            const responseData = await response.json();
            console.log(responseData);
    
            // Reset form fields
            setfoodName('');
            setfoodType('');
            setfoodCategory('');
            setfoodDesc('');
            setfoodPrice(0);
            setFoodImage(null);

            setShouldRefresh(true);
        } catch (err) {
            console.error(err.message);
        }
    };
    const handleSelectItem = (item) => {
        setIsUpdating(true);
        setSelectedItem(item);
        setfoodName(item.foodname);
        setfoodType(item.foodtype);
        setfoodCategory(item.foodcategory);
        setfoodDesc(item.description); // Assuming 'description' is the correct field
        setfoodPrice(item.price);
        // Note: Since you don't allow editing the picture, we won't set it here
    };

    const resetForm = () => {
        // Reset form fields and switch to "add" mode
        setfoodName('');
        setfoodType('');
        setfoodCategory('');
        setfoodDesc('');
        setfoodPrice(0);
        setFoodImage(null);
        setSelectedItem(null);
        setIsUpdating(false);
    };


const handleSubmit = async (e) => {
    e.preventDefault();

    if (isUpdating) {
        // Call the updateFood function for updating the food item
        await updateFood(e);
    } else {
        // Add new food item logic here
        await addFood(e);
    }
};
    
    const handleDeleteItem = async (itemId) => {
        try {
            const response = await fetch(`${apiUrl}/deletefood/${itemId}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                throw new Error('Error while deleting food item');
            }
            // Optionally, refetch the food list or remove the item from the local state to update the UI
        } catch (error) {
            console.error(error.message);
        }
    }
    
    return (
        
            <div className="p-5 h-screen">
                <p id='paso3' className="text-h1 font-h1 font-bold mb-12 text-center"> Editar Menú </p>
                <div className="flex h-full">
                    <div className="w-2/3 p-4 flex flex-col justify-between overflow-auto">
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-3">

                                    <label className="block text-primary font-body-bold mb-2" htmlFor="nombre">Nombre del Plato*</label>
                                    <input value={foodName} onChange={(e) => setfoodName(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="nombre" type="text" placeholder="Nombre del Plato" />
                                    <label className="block text-primary font-body-bold mb-2 mt-2" htmlFor="nit">Tipo*</label>
                                    <div className="w-full relative mb-2 px-1 md:px-0">
                                        <select value={foodType} onChange={(e) => setfoodType(e.target.value)}  className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-3 py-2 md:pr-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                                            <option className="text-blue">Seleccionar</option>
                                            <option>Plato Fuerte</option>
                                            <option>Entrada</option>
                                            <option>Postre</option>
                                            <option>Bebida</option>
                                            </select>
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-8 md:pr-2 text-gray-700">
                                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                <path d="M5.5 7l4.5 4.5L14.5 7H5.5z" />
                                            </svg>
                                        </div>
                                    </div>
                                
                                </div>

                                <div className="w-full md:w-1/2 px-3 ">
                                    <label className="block text-primary font-body-bold mb-2" htmlFor="telefono">Descripción del Plato*</label>
                                    <textarea value={foodDesc} onChange={(e) => setfoodDesc(e.target.value)}  className="shadow appearance-none border rounded w-full py-10 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="telefono" type="text" placeholder="Breve descripción" ></textarea>
                                </div>

                                <div className="w-full md:w-1/2 px-3 mb-3 md:mb-0">
                                    <label className="block text-primary font-body-bold mb-2" htmlFor="nit">Categoria</label>
                                    <div className="w-full relative mb-2 px-1 md:px-0">
                                        <select  value={foodCategory} onChange={(e) => setfoodCategory(e.target.value)}  className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-3 py-2 pr-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                                            <option className="text-blue">Seleccionar</option>
                                            {categoriesImages.map((item, index) => (
                                                <option>{item.title}</option>                                                
                                            ))}
                                            <option>Bebidas</option>
                                            <option>Otro</option>
                                            </select>
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-8 md:pr-2 text-gray-700">
                                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                <path d="M5.5 7l4.5 4.5L14.5 7H5.5z" />
                                            </svg>
                                        </div>
                                    </div>
                                
                                </div>
                        
                                <div className="w-full md:w-1/2 px-3 mb-0">
                                    <label className="block text-primary font-body-bold mb-2" htmlFor="direccion">Precio</label>
                                    <input value={foodPrice} onChange={(e) => setfoodPrice(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="nombre" type="text" placeholder="Precio" />
                                </div>
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-3">
                                    <label className="block text-primary font-body-bold mb-2" htmlFor="direccion">Imagen del Plato</label>
                                    <input type="file" accept="image/*" onChange={(e) => setFoodImage(e.target.files[0])} className="shadow appearance-none border rounded w-full py-7 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="logo" />
                                </div>
                            </div>
                            <button type="submit" className="mx-1 mb-5 px-8 py-2 font-h1 font-bold text-h2 bg-ctacolor rounded-[20px] border-2 border-solid border-black">
                                {isUpdating ? "Actualizar" : "Añadir"}
                            </button>
                        </form>


                    </div>
                    <div className="border-l-2 border-gray-300 w-1/3 p-8 flex flex-col justify-between mb-20">
                        {Object.keys(groupedFood).map((foodtype) => (
                            <div key={foodtype}>
                                <p className="font-bold">{foodtype}:</p>
                                <ul>
                                {groupedFood[foodtype].map((item, index) => (
                                    <li key={index} className="flex justify-between items-center">
                                        <span className="cursor-pointer" onClick={() => handleSelectItem(item)}>{item.foodname}</span>
                                        <button className="ml-4 text-red-500" onClick={() => handleDeleteItem(item.foodid)}>-</button>
                                    </li>
                                ))}
                            </ul>
                            </div>
                        ))}
</div>
                </div>
            </div>
    )
}

const Editmenu = ({ userData }) => {
    const [restaurantname, setRestaurantname] = useState('');
    const navigate = useNavigate();
    const [food, setfood] = useState([]);
    const id = new URLSearchParams(window.location.search).get('id');
    const { username, restaurantid} = userData;
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const [shouldRefresh, setShouldRefresh] = useState(false);

    const getRes = async () => {
  
        try {
            const link = `${apiUrl}/res`+ id
            const response = await fetch(link);
            const jsonData = await response.json();
            setRestaurantname(jsonData.restaurantname);
  
        } catch (err) {
            console.log(err.message);
  
        }
    }

    const fetchMenuItems = async () => {
        const response = await fetch(`${apiUrl}/resfood/${id}`);
        const items = await response.json();
        setfood(items);
    };
  
    useEffect(() => {
        getRes();
        fetchMenuItems();
        console.log(food);
        setShouldRefresh(false);
    }, [shouldRefresh]);





    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            for (const item of food) {
                const formData = new FormData();
                formData.append('foodImage', item.image);
                formData.append('foodName', item.nombrePlato);
                formData.append('foodType', item.tipo);
                formData.append('foodCategory', item.categoria);
                formData.append('foodDesc', item.desc);
                formData.append('foodPrice', item.price);
                formData.append('restaurantId', item.restauntId);
                const response = await fetch(`${apiUrl}/foodadd`, {
                method: 'POST',
                body: formData
            });
    
                if (!response.ok) {
                    throw new Error('Error while adding food item');
                }
    
                const responseData = await response.json();
                console.log(responseData);
            }
        } catch (err) {
            console.error(err.message);
        }
        navigate('/Home');
    };
    

    return (
        <div className="bg-primarybg w-full overflow-hidden">
            <div className={`bg-navbar h-20 ${styles.paddingX} ${styles.paddingY}`}>
                <div className={`${styles.boxWidth}`}>
                    <Navbar name={username} userResid={restaurantid} />
                </div>
            </div>

                <div className={`bg-primarybg ${styles.paddingX} ${styles.flexStart}`}>
                    <div className={`${styles.boxWidth}`}>
                        <Information restaurantname={restaurantname} food={food} setfood={setfood} setShouldRefresh={setShouldRefresh} />
                    </div>
                </div>
                <form onSubmit={handleSubmit} className='undernav'>
                <div className="flex justify-end mr-1 md:mr-20">
                    <button type="submit" className= "  mr-5 mb-5 px-8 py-2 font-h1 font-bold text-h2 bg-ctacolor rounded-[20px] border-2 border-solid border-black">Enviar</button>
                </div>
            </form>
            <Footer /> 
        </div>
    );
}

export default Editmenu