import styles from "../style.js";
import Categories from "./Categories.js";
import Navbar from "./Navbar.js";
import Restaurants from "./Restaurants.js";
import Promotions from "./Promotions.js"; // Importamos el componente de promociones
import Footer from './Footer';
import React, { useState } from 'react';

const Home = ({ userData, searchTerm, onSearch, filters, setFilters, selectedCategory, setSelectedCategory, onClearFilters }) => {
  const [showFavorites, setShowFavorites] = useState(false);
  const [showPromotions, setShowPromotions] = useState(false); // Estado para alternar entre promociones y restaurantes

  const toggleShowFavorites = () => setShowFavorites(!showFavorites);

  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
  };

  const handleSetFilters = (filters) => {
    setFilters(filters);
  };

  const { username, restaurantid, userid } = userData;

  return (
    <div className="bg-primarybg w-full overflow-hidden">
      <div className={` bg-navbar h-20  ${styles.paddingX} ${styles.paddingY}`}>
        <div className={`${styles.boxWidth}`}>
          <Navbar name={username} userResid={restaurantid} userid={userid} onSearch={onSearch} onShowFavorites={toggleShowFavorites} onClearFilters={onClearFilters}/>
        </div>
      </div>

      <div className={`bg-primarybg ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <div className="font-logo-font">
            <Categories 
              onSelectCategory={handleSelectCategory} 
              selectedCategory={selectedCategory} 
              onSetFilters={handleSetFilters} 
              showPromotions={showPromotions} // Pasamos el estado al componente de categorías
              setShowPromotions={setShowPromotions} // Pasamos la función para alternar promociones/restaurantes
            />
          </div>
        </div>
      </div>

      <div className={`bg-primarybg ${styles.paddingX} ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          {showPromotions ? (
            <Promotions searchTerm={searchTerm} selectedCategory={selectedCategory} filters={filters} userid={userid} />
          ) : (
            <Restaurants searchTerm={searchTerm} selectedCategory={selectedCategory} filters={filters} userid={userid} showFavorites={showFavorites} />
          )}
        </div>
      </div>

      <Footer /> 
    </div>
  );
}

export default Home;
