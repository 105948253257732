import styles from "../style.js";
import Navbar from "./Navbar.js";
import Footer from './Footer';
import addres from '../assets/addres.jpg';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

const SelectRestaurant = ({ restaurants, setRestaurantData }) => {
  const [selectedRestaurant, setSelectedRestaurant] = useState("");
  const [restaurantId, setRestaurantId] = useState("");
  const [tempRestaurant, setTempRestaurant] = useState("");

  const onRestaurantSearch = (event) => {
      setTempRestaurant(event.target.value);
  };

  const handleRestaurantChange = ({ restaurantname, restaurantid }) => {
      setSelectedRestaurant(restaurantname);
      setRestaurantId(restaurantid);
      setTempRestaurant(restaurantname);  // Optionally, you might want to clear the search field here
      setRestaurantData({ selectedRestaurant: restaurantname, restaurantId: restaurantid });  // Updating parent state
  };

  return (
      <div className='search-bar-container'>
          <div className='input-wrapper'>
              <input
                  placeholder='Buscar restaurante...'
                  type="text"
                  onChange={onRestaurantSearch}
                  value={tempRestaurant}
                  className="search-input border-gray-400 hover:border-gray-500 px-3 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              />
          </div>
          <div className="search-results-container relative w-64 border border-gray-300 rounded-md shadow-lg">
              <div className="overflow-y-auto max-h-60">
                  {restaurants.filter(restaurant => {
                      const searchTerm = tempRestaurant.toLowerCase();
                      const restaurantName = restaurant.restaurantname.toLowerCase();
                      return searchTerm && restaurantName.includes(searchTerm) && restaurantName !== searchTerm;
                  }).map(restaurant => (
                      <div
                          className='option flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100'
                          onClick={() => handleRestaurantChange(restaurant)}
                          key={restaurant.restaurantid}
                      >
                          {restaurant.restaurantname}
                      </div>
                  ))}
              </div>
          </div>
      </div>
  );
}


const SelectUsers = ({ users, setUserInfo }) => {
  const [selectedUser, setselectedUser] = useState("");
  const [userid, setuserid] = useState("");
  const [tempUser, settempUser] = useState("");

  const onUserChnage = (event) => {
      settempUser(event.target.value);
  };

  const handleUserChange = ({ username, userid }) => {
      setselectedUser(username);
      setuserid(userid);
      settempUser(username);  // Optionally, you might want to clear the search field here
      setUserInfo({ selectedUser: username, userid: userid });  // Updating parent state
      console.log(userid);
  };

  return (
      <div className='search-bar-container'>
          <div className='input-wrapper'>
              <input
                  placeholder='Buscar Usuario...'
                  type="text"
                  onChange={onUserChnage}
                  value={tempUser}
                  className="search-input border-gray-400 hover:border-gray-500 px-3 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              />
          </div>
          <div className="search-results-container relative w-64 border border-gray-300 rounded-md shadow-lg">
              <div className="overflow-y-auto max-h-60">
                  {users.filter(user => {
                      const searchTerm = tempUser.toLowerCase();
                      const username = user.username.toLowerCase();
                      return searchTerm && username.includes(searchTerm) && username !== searchTerm;
                  }).map(user => (
                      <div
                          className='option flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100'
                          onClick={() => handleUserChange(user)}
                          key={user.userid}
                      >
                          {user.username}
                      </div>
                  ))}
              </div>
          </div>
      </div>
  );
}



const Asignres = ({ userData }) => {
  const [restaurants, setRestaurants] = useState([]);
  const [restaurantData, setRestaurantData] = useState({});
  const [users, setusers] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
      const getRestaurants = async () => {
          const response = await fetch(`${apiUrl}/res`, { credentials: 'include' });
          const data = await response.json();
          setRestaurants(data);
      };
      const getUsers = async () => {
        const response = await fetch(`${apiUrl}/allusers`, { credentials: 'include' });
        const data = await response.json();
        setusers(data);
    };

      getRestaurants();
      getUsers();
  }, [apiUrl, userData.userid]);

  const handleSubmit = async () => {
    try {
        const body = { userid: userInfo.userid, restaurantId: restaurantData.restaurantId };
        const response = await fetch(`${apiUrl}/ownerUpdate`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
            credentials: 'include'
        });
        if (response.ok) {
            alert("Restaurant updated successfully.");
        } else {
            alert("Failed to update the restaurant.");
        }
    } catch (error) {
        console.error("Failed to submit:", error);
        alert("Error updating the restaurant.");
    }
};

  return (
      <div className="bg-primarybg w-full overflow-hidden">
          <div className={`bg-navbar h-20 ${styles.paddingX} ${styles.paddingY}`}>
              <Navbar name={userData.username} userResid={userData.restaurantid} />
          </div>
          <div className={`bg-primarybg ${styles.flexStart}`}>
              <div className={`${styles.boxWidth}`}>
              <div className="flex flex-col pl-40 pr-40 pt-10">
              <SelectRestaurant restaurants={restaurants} setRestaurantData={setRestaurantData} />
              <div className="mt-4">
              <SelectUsers users={users} setUserInfo={setUserInfo} />
              </div>
              <button onClick={handleSubmit} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
                    Asignar
                </button>

                </div>
              </div>

          </div>
          <Footer />
      </div>
  );
}

export default Asignres;