import styles from "../style.js";
import Navbar from "./Navbar.js";
import Footer from './Footer';
import addres from '../assets/addres.jpg';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

const Information = ({ errors, restaurantData, setRestaurantData }) => { 

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRestaurantData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    return (
        <div className="p-5">
            <p id='paso2' className="text-h1 font-h1 font-bold mb-12 text-center">Editar la información de tu restaurante</p>
            <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-3">
                    <label className="block text-primary font-body-bold mb-2" htmlFor="nombre">Nombre del Restaurante*</label>
                    <input value={restaurantData.restaurantname} onChange={handleChange} name="restaurantname" className={`shadow appearance-none border ${errors.restaurantname ? 'border-red-500' : 'rounded'} w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`} id="nombre" type="text" placeholder="Restaurant Name" />
                    {errors.restaurantname && <p className="text-red-500 text-xs italic">{errors.restaurantname}</p>}
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-3">
                    <label className="block text-primary font-body-bold mb-2" htmlFor="direccion">Dirección*</label>
                    <input value={restaurantData.address} onChange={handleChange} name="address" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="direccion" type="text" placeholder="Street 123" />
                    {errors.address && <p className="text-red-500 text-xs italic">{errors.address}</p>}
                </div>

                <div className="w-full md:w-1/2 px-3 mb-3 md:mb-0">
                    <label className="block text-primary font-body-bold mb-2" htmlFor="telefono">Telefono</label>
                    <input value={restaurantData.phone} onChange={handleChange} name="phone" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="telefono" type="text" placeholder="123456789" />
                </div>

                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-3">
                    <label className="block text-primary font-body-bold mb-2" htmlFor="direccion">Pagina web / link del menú</label>
                    <input value={restaurantData.webpage} onChange={handleChange} name="webpage" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="direccion" type="text" placeholder="www.restaurant.com" />
                </div>

                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-3">
                    <label className="block text-primary font-body-bold mb-2" htmlFor="direccion">Horario*</label>
                    <textarea value={restaurantData.scheduler} onChange={handleChange} name="scheduler" className="shadow appearance-none border rounded w-full py-7 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="direccion" type="text" placeholder="Specify by days" ></textarea>
                </div>

                <div className="w-full md:w-1/2 px-3 ">
                    <label className="block text-primary font-body-bold mb-2" htmlFor="telefono">Descripción*</label>
                    <textarea value={restaurantData.short_desc} onChange={handleChange} name="short_desc" className="shadow appearance-none border rounded w-full py-7 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="telefono" type="text" placeholder="Short description" ></textarea>
                    {errors.short_desc && <p className="text-red-500 text-xs italic">{errors.short_desc}</p>}
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-3">
                    <label className="block text-primary font-body-bold mb-2" htmlFor="direccion">Actualizar Logo</label>
                    <input type="file" accept="image/*" onChange={(e) => setRestaurantData({ ...restaurantData, logo: e.target.value })} className="shadow appearance-none border rounded w-full py-7 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="logo" />
                    {errors.logo && <p className="text-red-500 text-xs italic">{errors.logo}</p>}
                </div>
                
                <div className="w-full md:w-1/2 px-3 ">
                    <label className="block text-primary font-body-bold mb-2" htmlFor="telefono">Añadir Imagenes del restaurante (Múltiples Imagenes)</label>
                    <input type="file" accept="image/*" multiple onChange={(e) => setRestaurantData({ ...restaurantData, resImages: e.target.files })} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="resImages" />
                </div>
            </div>
        </div>
    )
}

const Editrestaurant = ({ userData }) => {
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const [errors, setErrors] = useState({});
    const id = new URLSearchParams(window.location.search).get('id');
    // State for restaurant data
    const [restaurantData, setRestaurantData] = useState({
        restaurantname: '',
        address: '',
        phone: '',
        scheduler: '',
        city: '',
        webpage: '',
        short_desc: '',
        logo: '',
        resImages: []
    });

    const getRes = async () => {
        try {
            const link = `${apiUrl}/res/` + id;
            const response = await fetch(link);
            const jsonData = await response.json();
            setRestaurantData({
                ...restaurantData,
                restaurantname: jsonData.restaurantname,
                address: jsonData.address,
                phone: jsonData.phone,
                scheduler: jsonData.scheduler,
                city: jsonData.city,
                webpage: jsonData.webpage,
                short_desc: jsonData.short_desc,
                logo: jsonData.logo,
                // Assuming resImages is part of your data model
                resImages: jsonData.resImages 
            });
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        getRes();
    }, []);
    const { username, restaurantid } = userData;

    const validateForm = () => {
        const newErrors = {};
      
        if (!restaurantData.restaurantname.trim()) newErrors.restaurantname = 'Nombre del restaurante es requerido';
        if (!restaurantData.address.trim()) newErrors.address = 'Dirección es requerida';
        if (!restaurantData.short_desc.trim()) newErrors.short_desc = 'Descripción es requerida';
      
        setErrors(newErrors);
      
        return Object.keys(newErrors).length === 0;
      };
      
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        const logoFile = e.target.logo.files[0];
        const logoname = logoFile ? logoFile.name : '';
        let restaurantId
        // Prepare the data to be sent in the request
        const { restaurantname, address, phone, scheduler, city, webpage, short_desc, resImages } = restaurantData;
        
        try {
            
            // Update restaurant information excluding images
            const body = { restaurantname, address, phone, scheduler, city, webpage, short_desc };
            const response = await fetch(`${apiUrl}/resupdate/${id}`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(body)
            });
            const responseData = await response.json();
            if (!response.ok) {
                throw new Error('Failed to update restaurant information');
            }
            console.log("logo files",e.target.logo.files.length);

            // Handle logo upload if a new file is selected
            if (logoname!=='' && e.target.logo.files.length>0) {

                const body = { logoname };
                const response = await fetch(`${apiUrl}/reslogoupdate/${id}`, {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(body)
                });

                const logo = e.target.logo.files[0];
                const formData = new FormData();
                formData.append('image', logo);
                formData.append('restaurantId', id.toString()); // Ensure correct id string
                console.log("FormData prepared for logo upload:", Array.from(formData)); // Logging formData contents
    
                try {
                    const imgResponse = await fetch(`${apiUrl}/upload`, {
                        method: 'POST',
                        body: formData,
                    });
    
                    if (imgResponse.ok) {
                        const data = await imgResponse.json();
                        console.log('Uploaded logo URL:', data.imageUrl);
                    } else {
                        console.error('Error uploading the logo');
                    }
                } catch (error) {
                    console.error('Error uploading the logo:', error);
                }
            }
    
            // Handle restaurant images upload if new files are selected
            if (e.target.resImages.files.length > 0) {
                const imagesFormData = new FormData();
                Array.from(e.target.resImages.files).forEach(file => {
                    imagesFormData.append('resImages', file);
                });
                imagesFormData.append('restaurantId', id);
    
                const imagesResponse = await fetch(`${apiUrl}/uploadMultiple`, { // This endpoint needs to be defined in your server for multiple images upload
                    method: 'POST',
                    body: imagesFormData,
                });
    
                if (!imagesResponse.ok) {
                    throw new Error('Failed to upload restaurant images');
                }
            }
    
            alert('Información actualizada correctamente');
            navigate(`/Home`); // Adjust the path as needed
        } catch (error) {
            console.error('Error updating restaurant data:', error);
            alert('Error al subir la información, intentelo nuevamente');
        }
    };
    
    
    

    return (
        <div className="bg-primarybg w-full overflow-hidden">
            <div className={`bg-navbar h-20 ${styles.paddingX} ${styles.paddingY}`}>
                <div className={`${styles.boxWidth}`}>
                    <Navbar name={username} userResid={restaurantid} />
                </div>
            </div>

            <form onSubmit={handleSubmit} className='undernav'>

                <div className={`bg-primarybg ${styles.paddingX} ${styles.flexStart}`}>
                    <div className={`${styles.boxWidth}`}>
                        <Information errors={errors} restaurantData={restaurantData} setRestaurantData={setRestaurantData}/>
                    </div>
                </div>
                <div className="flex justify-center">
                    <button type="submit" className= "  mx-1 mb-5 px-8 py-2 font-h1 font-bold text-h2 bg-ctacolor rounded-[20px] border-2 border-solid border-black">Guardar</button>
                </div>
            </form>
            <Footer /> 
        </div>
    );
}

export default Editrestaurant;