// Login.js
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import eye from '../assets/eye.svg';

const Login = ({ onLoginStatusChange }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const body = { email, password };
      const response = await fetch(`${apiUrl}/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: 'include', 
        body: JSON.stringify(body)
      });

      const responseData = await response.json();

      if (response.ok) {
        console.log(responseData);
        setEmail("");
        setPassword("");
        onLoginStatusChange(true); // Update login status on successful login
        navigate('/Home', { replace: true }); // Use 'replace' to avoid login page in history
        window.location.reload(); // Force a reload to update user info
      } else {
        console.log('Error en el registro:', responseData.message);
        alert(responseData.message);
      }
    } catch (err) {
      console.error('Error al intentar registrarse:', err.message);
      alert('Error al intentar registrarse. Credenciales incorrectas.');
    }
  };

  const handleSignInWithFacebook = () => {
    // Implement Facebook sign-in logic
  };

  const handleSignInWithGoogle = () => {
    // Implement Google sign-in logic
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      
      <div className="bg-white p-2 rounded-lg max-w-md w-full mx-4">
      <img
        src={`${apiUrl}/images/assets/chevron-left.svg`}
        className=" cursor-pointer w-8 h-8 mr-8 ml-0 pl-0"
        alt="resLogo" 
        onClick={() => navigate('/Home')} />
        <h2 className="text-2xl font-bold mb-6 text-center">Ingresar a EatsOpinion</h2>
       <div className="p-4 ">
        
        <form onSubmit={handleLogin}>
          <input 
            className="w-full p-2 border-2 border-black mb-4" 
            type="email" 
            placeholder="Correo" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
          />
          <div className="relative">
          <input 
            className="w-full p-2 border-2 border-black mb-4" 
            type={showPassword ? "text" : "password"}
            placeholder="Contraseña" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
          />
          <img
                  src={eye}
                  className="absolute right-3 top-3 cursor-pointer"
                  alt="Toggle visibility"
                  onClick={() => setShowPassword(!showPassword)}
                />
          </div>
          <button
            className="w-full bg-yellow-400 p-3 rounded font-bold text-black mb-4"
            type="submit"
          >
            Continuar
          </button>
        </form>
        <a href="/Resetpass" className="text-blue-600"> ¿Olvidaste tu contraseña?</a>
        <div className="flex flex-col items-center">
          {/*
          <button 
            className="w-full p-3 rounded font-bold text-black mb-2 border-2 border-red-500"
            onClick={handleSignInWithFacebook}
          >
            Continua con Facebook
          </button>
          <button 
            className="w-full p-3 rounded font-bold text-black border-2 border-red-500"
            onClick={handleSignInWithGoogle}
          >
            Continua con Google
          </button>
  */}
        </div>
        <p className="text-center mt-6">
          No tienes cuenta? 
          <a href="/Signup" className="text-blue-600"> Registrarse</a>
        </p>
      </div>
      </div> 
    </div>
  );
};

export default Login;
