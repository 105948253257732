//All the static list are put it here
import {
    bbq,
    burger,
    cake,
    fish,
    meat,
    pizza,
    sandwich,
    spaghetti,
    sushi, 
    taco, 
    vegetarian,
    comidaSana,
    wineglass, 
    peruana,
    asiatica,
    panaderia,
    cafeteria,
    comidaRapida,
    ejecutivo,
    arab,
    greek,
    paella,
    vegan,
    desayuno
 } from "../assets";
 

 export const categoriesImages = [
    {
        title: "Pizza",
        icon: pizza
    },
    {
        title: "Hamburguesa",
        icon: burger
    },
    {
        title: "Italiana",
        icon: spaghetti
    },
    {
        title: "Peruana",
        icon: peruana
    },
    {
        title: "Mexicana",
        icon: taco
    },
    {
        title: "Vegetariana",
        icon: vegetarian
    },
    {
        title: "Vegana",
        icon: vegan
    },
    {
        title: "Fitness",
        icon: comidaSana
    },
    {
        title: "Sandwich",
        icon: sandwich
    },
    
    {
        title: "Postres",
        icon: cake
    },
    {
        title: "Comida de Mar",
        icon: fish
    },
    {
        title: "Típica",
        icon: meat
    },
    
    {
        title: "Parrilla",
        icon: bbq
    },
    {
        title: "Sushi",
        icon: sushi
    },
    {
        title: "Asiática",
        icon: asiatica
    },
    {
        title: "Española",
        icon: paella
    },
    {
        title: "Arabe",
        icon: arab
    },
    {
        title: "Griega",
        icon: greek
    },
    {
        title: "Comida Rápida",
        icon: comidaRapida
    },
    {
        title: "Almuerzo Ejecutivo",
        icon: ejecutivo
    },
    {
        title: "Café",
        icon: cafeteria
    },
    {
        title: "Panadería",
        icon: panaderia
    },
    {
        title: "Desayuno",
        icon: desayuno
    },
    {
        title: "Cócteles",
        icon: wineglass
    },
    
];



export const menulogout = [
    {
        id: "inicia",
        title: "Iniciar Sesión",
    },
    {
        id: "registrarse",
        title: "Registrarse",
    },
    {
        id: "restaurante",
        title: "Pon tu Restaurante",
    },
    {
        id: "ayuda",
        title: "Centro de Ayuda",
    },
];


export const menulogin = [
    {
        id: "favoritos",
        title: "Restaurantes Favoritos",
    },
    {
        id: "cuenta",
        title: "Cuenta",
    },
    {
        id: "restaurante",
        title: "Pon tu Restaurante",
    },
    {
        id: "ayuda",
        title: "Centro de Ayuda",
    },
    {
        id: "cerrar",
        title: "Cerrar Sesión",
    },
];


export const menurest = [
    {
        id: "reporte",
        title: "Reporte",
    },
    {
        id: "restaurante",
        title: "Tu Restaurante",
    },
    {
        id: "ponerRestaurante",
        title: "Pon tu Restaurante",
    },
    {
        id: "ayuda",
        title: "Centro de Ayuda",
    },
    {
        id: "cerrar",
        title: "Cerrar Sesión",
    },
];



