import styles from "../style.js";
import Categories from "./Categories.js";
import Navbar from "./Navbar.js";
import Restaurants from "./Restaurants.js";
import NavbarRate from "./NavbarRate.js";
import TagManager from 'react-gtm-module';

import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

const ChoiceInput = (props) => {
  return (
      <div className="ratingStars">
          {[1, 2, 3, 4, 5].map((rate) => (
              <span
                  key={rate}
                  className={`star ${rate <= props.value ? 'selected' : ''}`}
                  onClick={() => props.onChange(rate)}
              >
                  ★
              </span>
          ))}
      </div>
  );
}




const PlatoRate = ({ index, platoRateData, setPlatoRateData, platos }) => {
  const [plato, setPlato] = useState("");
  const [idPlato, setIdPlato] = useState("");
  const [ratePlato, setRatePlato] = useState(0);
  const [commentPlato, setCommentPlato] = useState("");
  const [tempPlato, setTempPlato] = useState("");
  


  useEffect(() => {
    TagManager.dataLayer({
        dataLayer: {
          event: 'rate_open'
        },
      });
      const platorate = [...platoRateData]; // copy the complete array of plates here
      platorate[index] = { plato, idPlato, ratePlato, commentPlato }; // Use object notation to just change the corresponding plate
      setPlatoRateData(platorate);
  }, [plato, ratePlato, setPlatoRateData, index, platoRateData, idPlato, commentPlato]);

  const onPlatoSearch = (event) => {
      setTempPlato(event.target.value)
  }

  const handlePlatoChange = ({ foodname, foodid }) => {
      setPlato(foodname);
      setIdPlato(foodid);
      setTempPlato(foodname);
      console.log(foodid)
  };

  return (


      <>

          <div className='search-bar-container'>
              <div className='input-wrapper'>
                  <input placeholder='Buscar plato...'
                      type="text"
                      onChange={onPlatoSearch}
                      value={tempPlato}
                      className="search-input border-gray-400 hover:border-gray-500 px-3 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                      list="options" />
                      
              </div>
                <div className="search-bar-container relative w-64 border border-gray-300 rounded-md shadow-lg">
                    <div className="overflow-y-auto max-h-60">
                        {platos.filter(item => {
                        const searchTerm = tempPlato.toLowerCase();
                        const platoName = item.foodname.toLowerCase();
                        return searchTerm && platoName.includes(searchTerm) && platoName !== searchTerm;
                        }).map(item => (
                        <div
                            className='option flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100'
                            onClick={() => handlePlatoChange(item)}
                            key={item.foodid}
                            value={item.foodname}
                        >
                            {item.foodname}
                        </div>
                        ))}
                    </div>
                </div>

          </div>


          <ChoiceInput value={ratePlato} onChange={setRatePlato} />
          <textarea placeholder="Comentario" className="rate-comment" onChange={e => setCommentPlato(e.target.value)}></textarea>
      </>


  );

}



const RateSection = ({ sectionName, rating, setRating, comment, setComment }) => {
  return (

      <div className="section flex flex-col items-center w-full px-4 py-2 md:w-1/3 sm:px-2">
          <p className="font-h1 font-bold text-h1 flex items-center justify-center mx-auto">{sectionName}</p>
          <ChoiceInput value={rating} onChange={setRating} />
          <textarea placeholder="Comentario"
              className="rate-comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
          />
      </div>);

};
// Form component with three parts: Food, Service, and Environment
const Rate = ({userData}) => {
  const [foodRating, setFoodRating] = useState(null);
  const [foodComment, setFoodComment] = useState('');

  const [serviceRating, setServiceRating] = useState(null);
  const [serviceComment, setServiceComment] = useState('');
  const [logo, setLogo] = useState('');
  const [environmentRating, setEnvironmentRating] = useState(null);
  const [environmentComment, setEnvironmentComment] = useState('');
  const navigate = useNavigate();
  const {userid } = userData;  

  const [resValue, setResValue] = useState('');

  const [platos, setPlatos] = useState([]);

  const [platoRateData, setPlatoRateData] = useState([]);

  const [googleLink, setGoogleLink] = useState('');
  
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const id = new URLSearchParams(window.location.search).get('id');

  const [showModal, setShowModal] = useState(false); // State to control modal visibility


  const getPlatos = async () => {
      try {
          const link =`${apiUrl}/platos/` + id
          const response = await fetch(link);
          const jsonData = await response.json();
          setPlatos(jsonData);

      } catch (err) {
          console.log(err.message);

      }

      try {
          const link = `${apiUrl}/res/`  + id
          const response = await fetch(link);
          const jsonData = await response.json();
          setResValue(jsonData.restaurantname);
          setLogo(jsonData.logo);
          setGoogleLink(jsonData.google_link);

      } catch (err) {
          console.log(err.message);

      }
  }

  useEffect(() => {
      getPlatos();
  }, []);

  const handleSubmit = async (e) => {
      e.preventDefault();
      console.log(platoRateData)
      try {
          const body = { foodRating, foodComment, serviceRating, serviceComment, environmentRating, environmentComment, id, platoRateData, userid };
          const response = await fetch( `${apiUrl}/surveyAdd`,
              {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(body)
              });
          console.log(response);
          //setPlatoRateData([]);
          //window.location = "/";
      } catch (err) {
          console.log(err.message)

      }



      if (googleLink && foodRating >= 4 && serviceRating >= 4 && environmentRating >= 4) {
        setFoodRating(0);
        setFoodComment('');
        setServiceRating(0);
        setServiceComment('');
        setEnvironmentRating(0);
        setEnvironmentComment('');
        setPlatoRateData([]);
        setPlatos([]);
        setShowModal(true);
      } else { // Optionally, navigate or reset form here if modal is not to be shown 
        setFoodRating(0);
        setFoodComment('');
        setServiceRating(0);
        setServiceComment('');
        setEnvironmentRating(0);
        setEnvironmentComment('');
        setPlatoRateData([]);
        setPlatos([]);
        navigate('/Home');
    }

  };

  const ratePlato = (e) => {
      e.preventDefault();
      const newRate = { nombrePlato: "", idPlato: "", ratePlato: "", commentPlato: "" };
      setPlatoRateData([...platoRateData, newRate]);
  }

  const closeModal = () => {
    setShowModal(false);
    navigate('/Home');
  };


  return (
    <div className={` bg-navbar h-20  ${styles.paddingX} ${styles.paddingY}`}>
     <div className={`${styles.boxWidth}`}>
          <NavbarRate id={id} resName={resValue} logo={logo} />

          <form onSubmit={handleSubmit} className='undernav'>

              <div className='rateSection'>
                  <RateSection sectionName="Comida" rating={foodRating} setRating={setFoodRating} comment={foodComment} setComment={setFoodComment} />

                  <RateSection sectionName="Servicio" rating={serviceRating} setRating={setServiceRating} comment={serviceComment} setComment={setServiceComment} />

                  <RateSection sectionName="Ambiente" rating={environmentRating} setRating={setEnvironmentRating} comment={environmentComment} setComment={setEnvironmentComment} />


              </div><br></br>
              <div className='flex flex-col sm:flex-row items-center justify-center gap-4 mt-4'>
                  <button onClick={ratePlato} className='mb-4 bg-navbar hover:bg-blue-500 text-white font-semibold hover:text-white py-2 px-4 border-2 border-black hover:border-transparent rounded-[20px] w-1/2' > Calificar Comida</button>
              </div>
              <div className="rateSection">
              {platoRateData.map((plato, index) => (
                    <div key={index}  className="section flex flex-col items-center w-full px-4 py-2 md:w-1/3 sm:px-2">
                        <PlatoRate
                        index={index}
                        platoRateData={platoRateData}
                        setPlatoRateData={setPlatoRateData}
                        platos={platos}
                        />
                        {index === platoRateData.length - 1 && (
                        <div className="flex justify-end w-full">
                            <button
                            onClick={ratePlato}
                            className="mx-1 px-3 my-2 bg-ctacolor rounded-[20px] border-2 border-solid border-black"
                            >
                            +
                            </button>
                        </div>
                        )}
                    </div>
                    ))}
              </div>

            <div className="flex flex-col sm:flex-row items-center justify-center gap-4 mt-4">
              <button type="submit" className= " mb-3 mx-1 px-5 font-h1 font-bold text-h2 bg-ctacolor rounded-[20px] border-2 border-solid border-black">Enviar</button>
            </div>
          </form>

          </div>

    {/* Modal component */}
    {showModal && (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center">
        <div className="bg-white border-2 border-gray-300 rounded-lg p-6 max-w-sm mx-auto shadow-lg relative">
        {/* Close icon at the top right corner */}
        <button 
            className="absolute top-0 right-0 m-4 text-black bg-white rounded-full p-1 leading-none border border-gray-300"
            onClick={closeModal}
        >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </button>
        <p className="text-primary font-body text-justify mb-4 mt-6 ">
        ¡Tu opinión marca la diferencia! Agradecemos que hayas compartido tu experiencia. ¿te animarías a contarlo también en Google? Tu reseña es valiosa y ayuda a otros comensales a descubrirnos.
        </p>
        <a href={googleLink} target="_blank" rel="noopener noreferrer" className="block mt-4 text-ctacolor font-logo-font text-center">
            Compartir en Google
        </a>
        {/* Close button at the bottom */}
        <div className="flex justify-center">
        <button
          onClick={closeModal}
          className="mt-4 bg-contrast text-white font-bold py-2 px-4 rounded hover:bg-red-600 transition duration-300 ease-in-out"
        >
          Cerrar
        </button>
      </div>
        </div>
    </div>
    )}   

        
  </div>
  );
};



export default Rate;