import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import styles from "../style.js";
import { categoriesImages } from '../constants'
import addres from '../assets/addres.jpg';
import { useNavigate } from 'react-router-dom';

const PromotionManager = () => {
    const [promotionName, setPromotionName] = useState('');
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [image, setImage] = useState(null);
    const [promotions, setPromotions] = useState([]);
    const [selectedPromotion, setSelectedPromotion] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const restaurantId = new URLSearchParams(window.location.search).get('id');

    useEffect(() => {
        fetchPromotions();
    }, []);

    const fetchPromotions = async () => {
        try {
            const response = await fetch(`${apiUrl}/promotionsres/${restaurantId}`);
            const data = await response.json();
            setPromotions(data);
        } catch (error) {
            console.error('Error fetching promotions:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (promotions.length >= 2 && !isEditing) {
            alert('You can only create up to 2 promotions.');
            return;
        }

        const formData = new FormData();
        formData.append('restaurantId', restaurantId);
        formData.append('promotionName', promotionName);
        formData.append('description', description);
        formData.append('startDate', startDate);
        formData.append('endDate', endDate);
        if (image) {
            formData.append('image', image);
        }

        try {
            const url = isEditing ? `${apiUrl}/promotions/${selectedPromotion.promotionid}` : `${apiUrl}/promotions/add`;
            const method = isEditing ? 'PUT' : 'POST';

            const response = await fetch(url, {
                method: method,
                body: formData,
            });

            if (!response.ok) throw new Error('Error while saving promotion');

            const updatedPromotion = await response.json();
            setPromotions(prev => {
                if (isEditing) {
                    return prev.map(promo => promo.promotionid === updatedPromotion.promotionid ? updatedPromotion : promo);
                }
                return [...prev, updatedPromotion];
            });

            resetForm();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleDelete = async (promotionId) => {
        try {
            const response = await fetch(`${apiUrl}/promotions/${promotionId}`, {
                method: 'DELETE',
            });
            if (!response.ok) throw new Error('Error deleting promotion');
            setPromotions(prev => prev.filter(promo => promo.promotionid !== promotionId));
        } catch (error) {
            console.error('Error deleting promotion:', error);
        }
    };

    const handleEdit = (promotion) => {
        setSelectedPromotion(promotion);
        setPromotionName(promotion.promotionname);
        setDescription(promotion.description);
        setStartDate(promotion.start_date);
        setEndDate(promotion.end_date);
        setIsEditing(true);
    };

    const resetForm = () => {
        setPromotionName('');
        setDescription('');
        setStartDate('');
        setEndDate('');
        setImage(null);
        setSelectedPromotion(null);
        setIsEditing(false);
    };
    function formatDateForInput(dateString) {
        if (!dateString) return '';
        const localDate  = new Date(dateString);
        const date  = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
        // Extract the year, month, and day
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 because months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const formattedStartDate = selectedPromotion ? formatDateForInput(selectedPromotion.start_date) : '';
    const formattedEndDate = selectedPromotion ? formatDateForInput(selectedPromotion.end_date) : '';
    
    function formatDateForDisplay(dateString) {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    return (
        <div className="bg-primarybg w-full overflow-hidden">
            <div className={`bg-navbar h-20 ${styles.paddingX} ${styles.paddingY}`}>
                <div className={`${styles.boxWidth}`}>
                    <Navbar/>
                </div>
            </div>
            <div className="p-6">
                <h2 className="text-center text-2xl font-bold mb-6">Gestión de Promociones</h2>
                <div className="flex flex-col md:flex-row gap-6">
                    {/* Form Section */}
                    <div className="md:w-2/3 p-4 bg-white shadow rounded-lg">
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <label className="block text-gray-700 font-bold">Nombre de la Promoción</label>
                                <input
                                    type="text"
                                    value={promotionName}
                                    onChange={(e) => setPromotionName(e.target.value)}
                                    className="w-full p-2 border border-gray-300 rounded"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 font-bold">Descripción</label>
                                <textarea
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    className="w-full p-2 border border-gray-300 rounded"
                                    required
                                ></textarea>
                            </div>
                            <div className="flex gap-4">
                                <div className="w-1/2">
                                    <label className="block text-gray-700 font-bold">Fecha de Inicio</label>
                                    <input
                                        type="date"
                                        value={formatDateForInput(startDate)} 
                                        onChange={(e) => setStartDate(e.target.value)}
                                        className="w-full p-2 border border-gray-300 rounded"
                                        required
                                    />
                                </div>
                                <div className="w-1/2">
                                    <label className="block text-gray-700 font-bold">Fecha de Fin</label>
                                    <input
                                        type="date"
                                        value={formatDateForInput(endDate)} 
                                        onChange={(e) => setEndDate(e.target.value)}
                                        className="w-full p-2 border border-gray-300 rounded"
                                        required
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block text-gray-700 font-bold">Imagen</label>
                                <input
                                    type="file"
                                    onChange={(e) => setImage(e.target.files[0])}
                                    className="w-full p-2 border border-gray-300 rounded"
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                className="bg-yellow-500 text-white py-2 px-6 rounded hover:bg-yellow-600"
                            >
                                {isEditing ? 'Actualizar' : 'Añadir'}
                            </button>
                        </form>
                    </div>

                    {/* Promotions List Section */}
                    <div className="md:w-1/3 p-4 bg-white shadow rounded-lg">
                        <h3 className="font-bold text-gray-700 mb-4">Promociones Existentes</h3>
                        <ul className="space-y-3">
                            {promotions.map(promo => (
                                <li key={promo.promotionid} className="p-3 border-b border-gray-200">
                                    <div className="flex justify-between items-center">
                                        <span
                                            className="font-semibold text-gray-800 cursor-pointer hover:underline"
                                            onClick={() => handleEdit(promo)}
                                        >
                                            {promo.promotionname}
                                        </span>
                                        <button
                                            onClick={() => handleDelete(promo.promotionid)}
                                            className="text-red-500 hover:underline"
                                        >
                                            Eliminar
                                        </button>
                                    </div>
                                    <p className="text-sm text-gray-600">{promo.description}</p>
                                    <p className="text-xs text-gray-500">
                                        Desde: {formatDateForDisplay(promo.start_date)} Hasta: {formatDateForDisplay(promo.end_date)}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PromotionManager;
