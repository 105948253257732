import ig from '../assets/instagram.svg';
import yt from '../assets/youtube.svg';

const Footer = () => {
    return (
        <footer className="bg-navbar text-white p-4 text-center mt-8">
        <div className="flex justify-center items-center gap-4">
          <a href="https://www.instagram.com/eats.opinion/" target="_blank" rel="noopener noreferrer">
            <img src={ig} alt="Instagram" className="h-6 w-6" /> {/* Adjust size as needed */}
            
          </a>
          <p className="font-h1">|</p>
          <a href="https://www.youtube.com/@Eatsopinion/videos" target="_blank" rel="noopener noreferrer">
            <img src={yt} alt="Youtube" className="h-6 w-6" /> {/* Adjust size as needed */}
          </a>
          <p className="font-h1">|</p>
          <p className="font-h1">
            Celular: <a href="tel:+573044004519" className="hover:underline">+57 3044004519</a> |
            Email: <a href="mailto:eatsopinon@gmail.com" className="hover:underline">eatsopinion@gmail.com</a>
          </p>
        </div>
      </footer>
    );
  };
  
  export default Footer;