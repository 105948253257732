const styles = {
    boxWidth: "xl:max-w-[1280px] w-full",

    accentText: "font-accent text-accent",
    bodyBoldText: "font-body-bold text-body-bold",
    bodyText: "font-body text-body",
    h1Style: "font-h1 text-h1",
    h2Style: "font-h2 text-h2",
    logoFontStyle: "font-logo-font text-logo-font",

    
    flexCenter: "flex justify-center items-center",
    flexStart: "flex justify-center items-start",

    paddingX: "sm:px-16 px-0.05",
    paddingY: "sm:py-1 py-0.2",
    padding: "sm:px-16 px-6 sm:py-12 py-4",

    marginX: "sm:mx-16 mx-6",
    marginY: "sm:my-16 my-6",
};

export const layout = {
    section: `flex md:flex-row flex-col ${styles.paddingY}`,
    sectionReverse: `flex md:flex-row flex-col-reverse ${styles.paddingY}`,

    sectionImgReverse: `flex-1 flex ${styles.flexCenter} md:mr-10 mr-0 md:mt-0 mt-10 relative`,
    sectionImg: `flex-1 flex ${styles.flexCenter} md:ml-10 ml-0 md:mt-0 mt-10 relative`,

    sectionInfo: `flex-1 ${styles.flexStart} flex-col`,
};

export default styles;