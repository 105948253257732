import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import eye from '../assets/eye.svg';


const SignUpPage = ({ onLoginStatusChange }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const body = { name, phone, email, password };
      const response = await fetch( `${apiUrl}/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
      });

      const responseData = await response.json();

      if (response.ok) {
        console.log(responseData);
        await initiateLogin(email, password); // Initiate login after successful registration
      } else {
        console.log('Error en el registro:', responseData.message);
        alert(responseData.message);
      }

    } catch (err) {
      console.error('Error al intentar registrarse:', err.message);
      alert('Error al intentar registrarse. Inténtelo de nuevo más tarde.');
    }
  };

  const initiateLogin = async (email, password) => {
    try {
      const body = { email, password };
      const response = await fetch( `${apiUrl}/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: 'include', 
        body: JSON.stringify(body)
      });

      const responseData = await response.json();

      if (response.ok) {
        console.log(responseData);
        onLoginStatusChange(true); // Update login status on successful login
        setEmail("");
        setPassword("");
        navigate('/Home', { replace: true }); // Use 'replace' to avoid login page in history
        window.location.reload(); // Force a reload to update user info
      } else {
        console.log('Error en el registro:', responseData.message);
        alert(responseData.message);
      }
    } catch (err) {
      console.error('Error al intentar registrarse:', err.message);
      alert('Error al intentar registrarse. Credenciales incorrectas.');
    }
  };

  const handleTermsClick = () => {
    // URL of the terms PDF (modify as per your server setup)
    const termsPdfUrl = `${apiUrl}/Images/assets/terms.pdf`;
    window.open(termsPdfUrl, '_blank');
  };


  // Handlers for form submission and third-party sign-ins
    return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-2 rounded-lg max-w-md w-full mx-4">
      <img
        src={`${apiUrl}/images/assets/chevron-left.svg`}
        className=" cursor-pointer w-8 h-8 mr-8 ml-0 pl-0"
        alt="resLogo" 
        onClick={() => navigate('/Home')} />
        <h2 className="text-2xl font-bold mb-6 text-center">Crear Cuenta de EatsOpinion</h2>
        <div className="p-4">
        <form onSubmit={handleSignUp}>
          <input 
            className="w-full p-2 border-2 border-black mb-4" 
            type="text" 
            placeholder="Nombre Completo" 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
          />
          <input 
            className="w-full p-2 border-2 border-black mb-4" 
            type="tel" 
            placeholder="Celular" 
            value={phone} 
            onChange={(e) => setPhone(e.target.value)} 
          />
          <input 
            className="w-full p-2 border-2 border-black mb-4" 
            type="email" 
            placeholder="Correo" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
          />
          <div className="relative">
          <input 
            className="w-full p-2 border-2 border-black mb-4" 
            type={showPassword ? "text" : "password"}
            placeholder="Contraseña" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
          />
          <img
                  src={eye}
                  className="absolute right-3 top-3 cursor-pointer"
                  alt="Toggle visibility"
                  onClick={() => setShowPassword(!showPassword)}
                />
          </div>
          <div className="flex items-center mb-6">
            <input 
              className="mr-2" 
              type="checkbox" 
              checked={termsAccepted} 
              onChange={(e) => setTermsAccepted(e.target.checked)} 
            />
            <label htmlFor="terms">
            Acepto los{" "}
              <span 
                className="text-blue-600 cursor-pointer" 
                onClick={handleTermsClick}>
                  términos y condiciones
              </span>
            </label>
          </div>
          <button
            onClick={handleSignUp} 
            className="w-full bg-yellow-400 p-3 rounded font-bold text-black mb-4"
            type="submit"
          >
            Continuar
          </button>
        </form>
        <div className="flex flex-col items-center">
          {/*
          <button 
            className="w-full p-3 rounded font-bold text-black mb-2 border-2 border-red-500"
            onClick={handleSignInWithFacebook}
          >
            Continua con Facebook
          </button>
          <button 
            className="w-full p-3 rounded font-bold text-black border-2 border-red-500"
            onClick={handleSignInWithGoogle}
          >
            Continua con Google
          </button>
  */}
        </div>
        <p className="text-center mt-6">
          Ya tienes una cuenta? 
          <a href="/Login" className="text-blue-600"> Iniciar Sesión</a>
        </p>
      </div>
      </div>
    </div>
  );
};

export default SignUpPage;
