import styles from "../style.js";
import Navbar from "./Navbar.js";
import Footer from './Footer';
import addres from '../assets/addres.jpg';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import star from '../assets/star.svg';
import menu from '../assets/menus.svg';
import location from '../assets/location.svg';
import defaultImage from '../assets/anon.svg'; 
import phoneImage from '../assets/phone.svg'; 
import defaultFood from '../assets/food default.svg'; // Importa la imagen por defecto
import TagManager from 'react-gtm-module';

const Information = ({ id, restaurantname, logo }) => {
    const [imageFiles, setImageFiles] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const s3url = process.env.REACT_APP_S3_URL;

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await fetch(`${apiUrl}/restaurant-images/${id}`);
                const images = await response.json();
                setImageFiles(images);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, [id]);

    const handleImageClick = (file) => {
        setSelectedImage(`${s3url}/Images/restaurants/${id}/res/${file}`);
    };

    const closeModal = () => {
        setSelectedImage(null);
    };

    return (
        <div className="max-w-screen-xl mx-auto pt-6">
            <p id='resName' className="text-h1 font-h1 font-bold mb-2 text-center">{restaurantname}</p>
            <div className="flex flex-col md:flex-row items-start">
                <div className="flex justify-center w-full md:w-1/3 lg:w-1/4 pb-8">
                    <img
                        src={`${s3url}/Images/restaurants/${id}/${logo}`}
                        className="w-64 h-64 object-cover rounded-lg shadow-lg"
                        onClick={() => handleImageClick(logo)}
                    />
                </div>
                <div className="w-full md:w-2/3 lg:w-3/4 flex flex-row flex-wrap">
                    {imageFiles.map((file, index) => (
                        <div key={index} className="w-1/2 md:w-1/2 lg:w-1/4 p-1">
                            <img
                                src={`${s3url}/Images/restaurants/${id}/res/${file}`}
                                alt={`Restaurant View ${index + 1}`}
                                className="w-full h-48 object-cover rounded-lg shadow-lg cursor-pointer"
                                onClick={() => handleImageClick(file)}
                            />
                        </div>
                    ))}
                </div>
            </div>

            {selectedImage && (
                <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="modal-content relative p-4 bg-white border border-gray-300 rounded-lg max-w-3xl max-h-full overflow-auto">
                        <img
                            src={selectedImage}
                            className="rounded-lg shadow-lg"
                            alt="Enlarged view"
                            style={{ maxHeight: '80vh' }}
                        />
                        <button
                            className="absolute top-0 right-0 m-4 text-black bg-white rounded-full p-1 leading-none border border-gray-300"
                            onClick={closeModal}
                            aria-label="Close"
                        >
                            &times;
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

const Description = ({id, restaurantname,address,webpage,scheduler,phone,short_desc,categories, city, resavg}) => { 
    const navigate = useNavigate();

    const handleNavigate = () => {
        TagManager.dataLayer({
            dataLayer: {
              event: 'rate_open',
            },
          });
        navigate(`/Rate?id=${id}`);
    };

    return (
        <div className="border-b-2 border-gray-300 max-w-screen-xl mx-auto pb-2">
            <div className="flex flex-col md:flex-row">
                <div className="flex flex-col w-full md:w-1/2 lg:w-3/4 p-3">
                    <button onClick={handleNavigate} className="font-h1 font-bold underline text-navbar text-h1 ml-8 md:ml-1 md:pr-64">
                        Califícanos
                    </button>
                    <div className="flex items-center mb-2">
                        <img className="ml-2 fw-[47px] h-[44px]" alt="Iconly bold star" src={star} />
                        <p className="ml-4 font-h1 font-bold text-h1 ">{resavg} </p>
                    </div>
                    <div className="flex items-center mb-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="71" height="72" viewBox="0 0 71 72" fill="none">
                            <g clip-path="url(#clip0_213_2277)">
                                <path d="M35.5 9.07495L11.8333 26.825V62.325H59.1667V26.825L35.5 9.07495ZM36.9792 37.1791C36.9792 39.6345 34.9971 41.6166 32.5417 41.6166V53.45H29.5833V41.6166C27.1279 41.6166 25.1458 39.6345 25.1458 37.1791V28.3041H28.1042V37.1791H29.5833V28.3041H32.5417V37.1791H34.0208V28.3041H36.9792V37.1791ZM44.375 53.45H41.4167V43.0958H38.4583V34.2208C38.4583 30.9666 41.1208 28.3041 44.375 28.3041V53.45Z" fill="black"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_213_2277">
                                    <rect width="71" height="71" fill="white" transform="translate(0 0.199951)"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <p className="font-body text-h2 ">{categories} </p>
                    </div>
                    <div className="flex items-center mb-2">
                        <img className="ml-5 fw-[47px] h-[44px]" alt="Iconly bold star" src={location} />
                        <p className="font-body text-h2 ml-6 ">{city}, {address} </p>
                    </div>
                    <div className="flex items-center mb-4 mt-2">
                        <img className="ml-5 fw-[40px] h-[40px]" alt="Iconly bold star" src={phoneImage} />
                        <p className="font-body text-h2 ml-4 ">{phone} </p>
                    </div>
                    <div className="flex items-center mb-2">
                        <img className="ml-2 fw-[44px] h-[44px]" alt="Iconly bold star" src={menu} />
                        <a href={webpage} className="font-h1 font-bold underline text-h1 ml-2">Pagina Web</a>
                    </div>
                    <div className="mt-4 mb-2">
                        <p className="ml-4 font-h1 font-bold text-h1 ">Horario</p> 
                        <p className="font-body text-h2 ml-4"> {scheduler}</p>
                    </div>
                </div>
                <div className="w-full md:w-1/2 p-1">
                    <p className="font-body text-h2 text-justify pl-10 mr-5">{short_desc}</p>
                </div>
            </div>
        </div>
    );
};

const CircularProgress = ({ score, total = 5, onClick }) => {
    const radius = 40;
    const circumference = 2 * Math.PI * radius;
    const progress = score / total;
    const dashoffset = circumference * (1 - progress);

    return (
        <div className="relative inline-flex items-center justify-center " onClick={onClick}>
            <svg className="transform -rotate-90" width="120" height="120">
                <circle
                    cx="60"
                    cy="60"
                    r={radius}
                    stroke="#e6e6e6"
                    strokeWidth="10"
                    fill="transparent"
                />
                <circle
                    cx="60"
                    cy="60"
                    r={radius}
                    stroke="#4ade80"
                    strokeWidth="10"
                    fill="transparent"
                    strokeDasharray={circumference}
                    strokeDashoffset={dashoffset}
                />
            </svg>
            <div className="absolute">
                <span className="text-3xl font-bold">{score}</span>
                <span className="text-lg">/{total}</span>
            </div>
        </div>
    );
};

const UserReview = ({ userId, image, username, rating, comment }) => {
    const userProfileImage = defaultImage;
    const userDisplayName = userId ? username : 'Anonymous';

    const stars = [];
    for (let i = 0; i < 5; i++) {
        stars.push(i < rating ? '⭐' : '');
    }

    return (
        <div className="flex items-center space-x-4 bg-white p-4 rounded md:ml-8 ">
            <img src={userProfileImage} alt={userDisplayName} className="w-10 h-10 rounded-full" />
            <div>
                <div className="flex items-center">
                    <h3 className="text-lg font-semibold">{userDisplayName}</h3>
                </div>
                <span className="">{stars}</span>
                <p>{comment}</p>
            </div>
        </div>
    );
};

const Rating = ({ id, resavg, foodavg, serviceavg, envavg, surveys }) => {
    const [showModal, setShowModal] = useState(false);
    const [currentCategory, setCurrentCategory] = useState('');
    const [categoryComments, setCategoryComments] = useState([]);

    const handleCircularProgressClick = (category) => {
        const categoryKey = `${category}comment`;
        const filteredComments = surveys.map(survey => ({
            id: survey.id,
            userId: survey.userid,
            image: survey.userid,
            username: survey.username,
            rating: survey[`rating${category}`],
            comment: survey[categoryKey],
        })).filter(survey => survey.comment);

        setCurrentCategory(category);
        setCategoryComments(filteredComments);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <div className="border-b-2 border-gray-300 max-w-screen-xl mx-auto pb-2">
            <p className="text-h1 font-h1 font-bold m-4 text-center">Calificaciones</p>
            <div className="flex md:flex-row">
                <div className="flex flex-col p-2">
                    <div className="flex flex-row p-1">
                        <div className="mb-8 w-1/2 md:w-1/4 flex-row justify-center" onClick={() => handleCircularProgressClick('food')}>
                            <p className="ml-4 font-h1 font-bold text-h1">Comida</p>
                            <CircularProgress score={foodavg} />
                            <p className="ml-5 font-h2 font-bold text-logo-font-sm cursor-pointer text-blue-500">Ver detalle</p>
                        </div>
                        <div className="flex flex-col md:flex-row mr-5 w-3/4">
                            {surveys.slice(0, 2).map(survey => (
                                <div className="m-2 w-1/2" key={survey.id}>
                                    <UserReview
                                        userId={survey.userid}
                                        image={survey.userid}
                                        username={survey.username}
                                        rating={survey.ratingfood}
                                        comment={survey.foodcomment}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex flex-row p-1">
                        <div className="mb-8 w-1/2 md:w-1/4 flex-row justify-center" onClick={() => handleCircularProgressClick('service')}>
                            <p className="ml-4 font-h1 font-bold text-h1">Servicio</p>
                            <CircularProgress score={serviceavg} />
                            <p className="ml-5 font-h2 font-bold text-logo-font-sm cursor-pointer text-blue-500">Ver detalle</p>
                        </div>
                        <div className="flex flex-col md:flex-row mr-5 w-3/4">
                            {surveys.slice(0, 2).map(survey => (
                                <div className="m-2 w-1/2" key={survey.id}>
                                    <UserReview
                                        userId={survey.userid}
                                        image={survey.userid}
                                        username={survey.username}
                                        rating={survey.ratingservice}
                                        comment={survey.servicecomment}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex flex-row p-1">
                        <div className="mb-8 w-1/2 md:w-1/4 flex-row justify-center" onClick={() => handleCircularProgressClick('environment')}>
                            <p className="ml-4 font-h1 font-bold text-h1">Ambiente</p>
                            <CircularProgress score={envavg} />
                            <p className="ml-5 font-h2 font-bold text-logo-font-sm cursor-pointer text-blue-500">Ver detalle</p>
                        </div>
                        <div className="flex flex-col md:flex-row mr-5 w-3/4">
                            {surveys.slice(0, 2).map(survey => (
                                <div className="m-2 w-1/2" key={survey.id}>
                                    <UserReview
                                        userId={survey.userid}
                                        image={survey.userid}
                                        username={survey.username}
                                        rating={survey.ratingenvironment}
                                        comment={survey.environmentcomment}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    {showModal && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 overflow-auto">
                            <div className="relative p-4 bg-white m-4 rounded-lg shadow-lg max-w-3xl mx-auto my-20">
                                <button
                                    className="absolute top-0 right-0 m-4 text-black bg-white rounded-full p-1 leading-none border border-gray-300"
                                    onClick={closeModal}
                                    aria-label="Close"
                                >
                                    &times;
                                </button>
                                <div>
                                    {categoryComments.map((comment) => (
                                        <div className="m-2 w-full" key={comment.id}>
                                            <UserReview
                                                userId={comment.userId}
                                                image={comment.image}
                                                username={comment.username}
                                                rating={comment.rating}
                                                comment={comment.comment}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const FoodRate = ({ foodid, foodname, price, rating, description, image, id }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [foodComments, setFoodComments] = useState([]);
    const numericRating = Number(rating);
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const s3url = process.env.REACT_APP_S3_URL;

    const starscom = [];
    for (let i = 0; i < 5; i++) {
        starscom.push(i < numericRating ? '⭐' : '');
    }

    const formattedPrice = price 
        ? `$${new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(price)}`
        : '';

    const openModal = async () => {
        try {
            const response = await fetch(`${apiUrl}/foodratings/${foodid}`);
            const data = await response.json();
            setFoodComments(data);
        } catch (error) {
            console.error('Error fetching food comments:', error);
        }
        setSelectedImage(`${s3url}/Images/restaurants/food/${image}`);
    };

    const closeModal = () => {
        setSelectedImage(null);
    };

    return (
        <>
            <div className="flex flex-col items-center space-x-4 bg-white p-4 rounded shadow-md">
                <p className="text-center font-bold">{foodname}</p>
                <img
                    src={image ? `${s3url}/Images/restaurants/food/${image}` : defaultFood}
                    alt={foodname}
                    className="w-20 h-20 object-cover rounded-lg shadow-lg cursor-pointer"
                    onClick={openModal}
                />
                <div className="flex flex-col items-center">
                    <div className="flex justify-center">
                        {starscom.map((star, index) => (
                            <span key={index}>{star}</span>
                        ))}
                    </div>
                    {formattedPrice && <p className="font-bold text-center">{formattedPrice}</p>}
                    <p className="text-justify">{description}</p>
                </div>
            </div>

            {selectedImage && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="relative p-4 bg-white border  border-gray-300 rounded-lg w-3/4 max-h-full overflow-auto">
                        <div className="flex justify-center">
                            <img
                                src={selectedImage}
                                className="rounded-lg shadow-lg"
                                alt="Food view"
                                style={{ maxWidth: '45%', maxHeight: '25%' }}
                            />
                        </div>
                        <div className="mt-4">
                            {foodComments.length > 0 ? (
                                foodComments.map((comment) => (
                                    <div key={comment.foodratingid} className="mb-4">
                                        <UserReview 
                                            userId={comment.surveyid}
                                            image={defaultImage}
                                            username={comment.username}
                                            rating={comment.foodrating}
                                            comment={comment.foodcomment}
                                        />
                                    </div>
                                ))
                            ) : (
                                <p>Sin Comentarios todavía.</p>
                            )}
                        </div>
                        <button
                            className="absolute top-0 right-0 m-4 text-black bg-white rounded-full p-1 leading-none border border-gray-300"
                            onClick={closeModal}
                            aria-label="Close"
                        >
                            &times;
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

const Food = ({ foodcomment, id }) => { 
    const [selectedCategories, setSelectedCategories] = useState([]);
    const categories = ["Plato Fuerte", "Entrada", "Postre", "Bebida"];

    const handleCategoryClick = (category) => {
        setSelectedCategories((prevSelected) =>
            prevSelected.includes(category)
                ? prevSelected.filter((cat) => cat !== category)
                : [...prevSelected, category]
        );
    };

    const filteredFood = selectedCategories.length > 0
        ? foodcomment.filter((item) => selectedCategories.includes(item.foodtype))
        : foodcomment;

    return (
        <div className="border-b-2 border-gray-300 max-w-screen-xl mx-auto pb-2">
            <p className="text-h1 font-h1 font-bold m-4 text-center">Platos y Puntaje</p>
            <div className="flex justify-center space-x-4 mb-4">
                {categories.map((category) => (
                    <button
                        key={category}
                        onClick={() => handleCategoryClick(category)}
                        className={`py-2 px-4 rounded-full font-bold ${
                            selectedCategories.includes(category)
                                ? "bg-black text-white"
                                : "border border-black text-black"
                        }`}
                    >
                        {category}
                    </button>
                ))}
            </div>
            <div className="flex flex-wrap justify-left m-5">
                {filteredFood.map((com) => (
                    <div className="p-2 w-1/2 md:w-1/4 min-w-[150px]" key={com.id}>
                        <FoodRate 
                            foodid={com.foodid}
                            foodname={com.foodname}
                            price={com.price}
                            rating={com.foodratings}
                            description={com.description}
                            image={com.image}
                            id={id}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

const Addfood = ({userData, onSearch}) => {
    const [restaurantname, setRestaurantname] = useState('');
    const [restaurantid, setRestaurantid] = useState('');
    const [webpage, setWebpage] = useState('');
    const [address, setAddress] = useState('');
    const [short_desc, setshort_desc] = useState('');
    const [scheduler, setScheduler] = useState('');
    const [categories, setCategories] = useState('');
    const [city, setCity] = useState('');
    const [phone, setPhone] = useState('');
    const [resavg, setResavg] = useState('');
    const [logo, setLogo] = useState('');
    const [foodavg, setFoodavg] = useState(0);
    const [serviceavg, setServiceavg] = useState(0);
    const [envavg, setEnvavg] = useState(0);
    const [surveys,setSurveys]=useState([]);
    const [foodcomment,setFoodComment]=useState([]);
    const navigate = useNavigate();
    const { username, userid } = userData;
    const resid = userData.restaurantid;

    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    const getRes = async () => {
        try {
            const link =  `${apiUrl}/res/` + id
            const response = await fetch(link);
            const jsonData = await response.json();
            setRestaurantname(jsonData.restaurantname);
            setRestaurantid(jsonData.restaurantid);
            setWebpage(jsonData.webpage);
            setAddress(jsonData.address);
            setshort_desc(jsonData.short_desc);
            setScheduler(jsonData.scheduler);
            setCategories(jsonData.categories);
            setCity(jsonData.city);
            setPhone(jsonData.phone);
            setLogo(jsonData.logo);
        } catch (err) {
            console.log(err.message);
        }
    }

    const getResAvg = async () => {
        try {
            const link = `${apiUrl}/resavg/` + id
            const resp = await fetch(link);
            const jsonDat = await resp.json();
            setResavg(jsonDat.overallaverage);
            setFoodavg(jsonDat.averageratingfood);
            setServiceavg(jsonDat.averageratingservice);
            setEnvavg(jsonDat.averageratingenvironment);
        } catch (err) {
            console.log(err.message);
        }
    }

    const getResComments = async () => {
        try {
            const link1= `${apiUrl}/ressurvey/`+ id
            const respoComments = await fetch(link1);
            const jsonDataComments = await respoComments.json();
            setSurveys(jsonDataComments);
        } catch (err) {
            console.log(err.message);
        }
    }

    const getFood = async () => {
        try {
            const linkfood =`${apiUrl}/resfood/`+ id
            const respofood = await fetch(linkfood);
            const jsonDatafood = await respofood.json();
            setFoodComment(jsonDatafood);
        } catch (err) {
            console.log(err.message);
        }
    }

    const id = new URLSearchParams(window.location.search).get('id');

    useEffect(() => {
        getRes();
        getResComments();
        getResAvg();
        getFood();
    }, [id]);

    return (
        <div className="bg-primarybg w-full overflow-hidden">
            <div className={`bg-navbar h-20 ${styles.paddingX} ${styles.paddingY}`}>
                <div className={`${styles.boxWidth}`}>
                    <Navbar name={username} onSearch={onSearch} userResid={resid} userid={userid} />
                </div>
            </div>
            <div className={`bg-primarybg ${styles.paddingX} ${styles.flexStart}`}>
                <div className={`${styles.boxWidth}`}>
                    <Information restaurantname={restaurantname} id={id} logo={logo}/>
                    <Description id={id} resavg={resavg} address={address} webpage={webpage} short_desc={short_desc} phone={phone} scheduler={scheduler} categories={categories} city={city}/>
                    <Rating resavg={resavg} foodavg={foodavg} serviceavg={serviceavg} envavg={envavg} surveys={surveys} id={id} />
                    <Food foodcomment={foodcomment} id={id} />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Addfood
